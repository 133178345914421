import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Switch, Typography, useTheme } from "@mui/material"

import Banner from "assets/imgs/BANNER.png"
import moneySVG from "assets/imgs/money.svg"
import BannerCarrousel from "components/carrousel";
import { QRCodeComponent } from "components/qrCode";
import useAuth from "context/auth";
import useBet from "context/bet";
import { useEffect, useRef, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { onChange } from "react-toastify/dist/core/store";
import { PrizeDrawsService } from "service/prizes";
import StorageService from "service/storage";
import { Roles, sendToShow } from "utils";
import RoutesPath from "utils/routesBases";
import { BsQrCode } from "react-icons/bs";
import { QrCode } from "./qrcode";
import useQuery from "hooks/useQuery";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdClose, MdFullscreen } from "react-icons/md";
import { BetService } from "service/bets";
import TableDetailsWithdrawView from "../client/creditsDetails";

import Card30 from 'assets/imgs/CARD 30.png'

const App = () => {

    const { prizesDraws, setPrizesDraws, setShowComissionSellerPurchase, showComissionSellerPurchase, ResetBet } = useBet();

    const { palette } = useTheme()

    const navigate = useNavigate();

    const { user } = useAuth();

    const theme = useTheme();

    const [openModalFirstPurchase, setOpenModalFirstPurchase] = useState(false)

    const [openModal30, setOpenModal30] = useState(false)

    const [openQrCode, setOpenQrCode] = useState(false)

    const indicationLink = `https://numerofavorito.com/convitedasorte?s=${user?.uuid}`

    const [loadingGuessCredit, setLoadingGuessCredit] = useState(false)

    const [openDetails, setOpenDetails] = useState(false);
    const [historic, setHistoric] = useState<{
        balance: number, // SALDO
        creditValue: number, // VALOR
        event: number | string, // DESCRIÇÃO
        createdAt: Date | string, // DATA
        kind: string, // NATUREZA,
        betUUID: string | null,
        clientCpf: string | null,
        clientName: string | null,
        eventNumber: number
    }[] | null
    >(null)
    const query = useQuery()

    const [code, setCode] = useState<string>()

    const copyLink = () => {
        navigator.clipboard.writeText(indicationLink);
        toast('Link copiado!', {
            type: 'success'
        })
    }
    useEffect(() => {
        PrizeDrawsService.get()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setPrizesDraws(data.prizeDraws);
                }
            })

        const _code = query.get("cartela")

        if (_code) {
            setCode(_code)
            setOpenQrCode(true)
        }
        let arrayDate = StorageService.getCountClickModal30() ?? []
        if (!arrayDate.includes(new Date().toLocaleDateString()) && arrayDate.length < 5) {
            setOpenModal30(true)
        }
        
        setTimeout(() => {
            sendToShow("button-sale-locale", "center") 
        }, 700);
    }, [])

    const onChangeShowComission = () => {
        StorageService.ViewComissionSeller(showComissionSellerPurchase ? 'false' : 'true')
        setShowComissionSellerPurchase(!showComissionSellerPurchase)
    }

    const verifyModal = () => {
        // let count = StorageService.getCountClickModalFirstPurchase() ?? "0"
        // if (Number(count) < 3) {
        //     setOpenModalFirstPurchase(true)
        // } else {
        //     setOpenModalFirstPurchase(false)
        //     ResetBet()
        //     navigate(RoutesPath.Revendedor.bet.home2.path)
        // }
        ResetBet()
        navigate(RoutesPath.Revendedor.bet.home2.path)
    }

    const onCloseModal = () => {
        setOpenModal30(false)
        let arrayDate = StorageService.getCountClickModal30() ?? []
        arrayDate.push(new Date().toLocaleDateString())
        StorageService.saveCountClickModal30(arrayDate)
    }

    const getAllMovements = (guess?: boolean) => {
        if (guess) {
            setLoadingGuessCredit(true)
        }
        BetService.creditsHistoric(guess)
            .then(({ data }) => {
                if (data) {
                    setHistoric(data)
                }
                if (guess) {
                    setLoadingGuessCredit(false)
                    setOpenDetails(true)
                }
            })
    }

    return (
        <Grid container>
            <Dialog open={openModal30} onClose={onCloseModal}>
                <DialogTitle>
                    <Grid container justifyContent={"flex-start"}>
                        <IconButton onClick={onCloseModal}>
                            <MdClose size={24} />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <img width={"100%"} src={Card30} alt="Modal 30%" />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCloseModal}>Fechar</Button>
                </DialogActions>
            </Dialog>
            <Grid container alignItems="center" gap={1} flexDirection={'column'} p={2}>
                {
                    user?.role === Roles.general &&
                    <Grid width={'100%'}>
                        <BannerCarrousel />
                    </Grid>
                }
                <Typography variant="h2" textAlign={"center"} fontWeight={"400"}>
                    <strong>{user?.role === Roles.general ? "Adm geral" : "Revendedor"}</strong>: <i>{user?.role === Roles.general ? user?.name : user?.name?.split(" ")[0]}</i>
                </Typography>
                {user?.role === Roles.seller && <Typography variant="h3" textAlign={"center"} fontWeight={"400"} mb={2}>
                    Código do Revendedor: <strong>{user?.alternativeId}</strong>
                </Typography>}
                <Typography variant="h4" fontWeight={"400"}>
                    Saldo Disponível: <strong style={{ color: (user?.credits ?? 0) < 0 ? 'red' : theme.palette.primary.main }}>
                        {user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </strong>
                </Typography>
                {user?.role === Roles.seller &&
                    <Grid container justifyContent={'space-between'} alignItems="center">
                        <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            fullWidth
                            sx={{
                                animation: 'pulseWithoutBoxShadow 2s infinite'
                            }}
                            onClick={(e) => {
                                navigate(RoutesPath.Revendedor.deposito.path)
                            }}
                            startIcon={<div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <Typography fontSize={20}>+</Typography>
                                <RiMoneyDollarCircleLine style={{
                                    marginLeft: 2
                                }} />
                            </div>}
                        >
                            ADICIONAR SALDO PARA APOSTAS
                        </Button>
                    </Grid>
                }
                <Button
                    onClick={() => navigate('meusaldo')}
                    color="inherit"
                    sx={{ textDecoration: 'underline', fontSize: 10 }}>(Detalhes do saldo)</Button>
            </Grid>
            {
                user?.role === Roles.seller &&
                <Grid container alignItems="center" justifyContent={"center"} gap={1} p={2} xs={12} >
                    <Divider style={{ borderColor: 'GrayText', borderBottomWidth: 'thin' }} />
                    <div>
                        <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={palette.secondary.main} textTransform={"uppercase"}>
                            CRÉDITO BONIFICADO PARA PAGAMENTO DE APOSTAS:
                        </Typography>
                        <Typography fontWeight="normal" color={palette.secondary.main} variant="h3" textAlign={"center"}>
                            {(user?.guessCredits ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                        <Typography color="GrayText" textAlign="center" fontSize={'.8rem'}>(Utilize esse crédito bonificado para pagamento de apostas)</Typography>
                    </div>

                    <Button
                        onClick={() => getAllMovements(true)}
                        color="inherit"
                        sx={{ textDecoration: 'underline', fontSize: 10 }}
                        startIcon={loadingGuessCredit && <CircularProgress size={24} />}
                    >(Detalhes do crédito)</Button>
                </Grid>
            }
            {
                user?.role === Roles.seller &&
                <>
                    <Grid container alignItems="center" gap={1} p={2} xs={12} onClick={onChangeShowComission}>
                        <Grid xs={2}>
                            <Switch
                                checked={showComissionSellerPurchase}
                                onChange={onChangeShowComission}
                            />
                        </Grid>
                        <Grid xs={9}>
                            <Typography color={theme.palette.grey[700]}>
                                Quero visualizar as comissões no momento da venda
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" gap={1} p={2}>
                        <Grid
                            id={"button-sale-locale"}
                            component={Button}
                            onClick={() => {
                                verifyModal()
                            }}
                            p={2}
                            fullWidth
                            variant="contained"
                            flexDirection={'column'}
                            gap={2}
                            borderRadius={3}
                            height={160}
                            sx={{
                                animation: 'pulseWithoutBoxShadow 2s infinite'
                            }}>
                            <img
                                src={moneySVG}
                                style={{ maxWidth: 50 }}
                                width="100%" />
                            <Typography variant="h3" color="white" textTransform={"uppercase"}>
                                Vender apostas <br /> presencialmente
                            </Typography>
                        </Grid>
                        <Divider sx={{
                            borderWidth: 1,
                            width: '100%',
                            mt: 2
                        }} />
                        <Typography width={"100%"} textAlign={"center"} color={"GrayText"}>OU</Typography>

                        <Grid
                            component={Button}
                            onClick={() => {
                                setOpenQrCode(true)
                            }}
                            p={2}
                            fullWidth
                            variant="contained"
                            flexDirection={'column'}
                            gap={2}
                            borderRadius={3}
                            sx={{
                                height: '160px',
                                backgroundColor: palette.secondary.main,
                                borderColor: palette.secondary.main,
                                '&:hover': {
                                    borderColor: palette.secondary.main,
                                    backgroundColor: palette.secondary.dark
                                }
                            }}>
                            <BsQrCode size={50} color={"white"} />
                            <Typography variant="h3" color={"white"} textTransform={"uppercase"}>
                                Vender apostas <br /> com cartela
                            </Typography>
                        </Grid>
                        <Divider sx={{
                            borderWidth: 1,
                            width: '100%',
                            mt: 2
                        }} />
                        <Button
                            onClick={copyLink}
                            sx={{ p: 2, display: 'flex', gap: 1, mt: 2, borderRadius: 3 }}
                            color={"success"}
                            variant="outlined">
                            <FaWhatsapp size={45} />
                            <Typography variant="h4" color={theme.palette.success.main} textAlign={"start"} textTransform={'none'}>
                                Copiar e enviar meu link de vendas pelo WhatsApp
                            </Typography>
                        </Button>
                        <Typography width={"100%"} textAlign={"center"} color={"GrayText"}>Se preferir, copie o link de vendas abaixo</Typography>
                        <Grid container p={2} bgcolor="#0000000A" alignItems="center" justifyContent="space-between">
                            <Typography width="calc(100% - 45px)" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {indicationLink}
                            </Typography>
                            <IconButton onClick={copyLink}>
                                <FiCopy />
                            </IconButton>
                        </Grid>
                        <Divider sx={{
                            borderWidth: 1,
                            width: '100%',
                            mt: 2
                        }} />
                        <Typography width={"100%"} textAlign={"center"} color={"GrayText"}>OU</Typography>
                        <QRCodeComponent
                            key={indicationLink}
                            link={indicationLink}
                            title="Baixar QRCODE de vendas"
                            midComponent={
                                <Typography width={"100%"} textAlign={"center"} color={"GrayText"} mt={-1}>Ou baixe o QRCODE de vendas</Typography>
                            } />
                    </Grid>
                </>
            }
            <QrCode
                open={openQrCode}
                handleClose={() => setOpenQrCode(false)}
                code={code}
            />

            <TableDetailsWithdrawView
                open={openDetails}
                handleClose={() => {
                    setOpenDetails(false)
                }}
                historic={historic}
            />
        </Grid>
    )
}

export default App;