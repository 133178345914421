import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormHelperText, Grid, InputBaseComponentProps, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom, KeyPixRandomMaskCustom } from "components/mask";
import { useForm } from "react-hook-form";
import { Validators } from "utils/validators";
import validator from "validator";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "context/auth";
import { NameMask, formatCPF, moneyMask, currencyToNumber, round } from "utils/mask";
import StorageService from "service/storage";
import { AuthService } from "service/auth";
import { toast } from "react-toastify";
import PinWithdraw from "./pin";
import { LoadingButton } from "@mui/lab";
import theme from "theme";
import PaymentMade from "components/paymentMade";
import RoutesPath from "utils/routesBases";
import { constants } from "utils";

export type keyPixTypeProp = 'phone' | 'cpf' | 'cnpj' | 'email' | 'uuid'

const typesPix = [
    { type: 'phone', text: 'Telefone' },
    { type: 'cpf', text: 'CPF' },
    { type: 'cnpj', text: 'CNPJ' },
    { type: 'email', text: 'E-mail' },
    { type: 'uuid', text: 'Chave aleatória' }
]

export const WithdrawPage = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch
    } = useForm<{ keypix: string }>();

    const { user } = useAuth();

    const navigate = useNavigate();

    const { pathname } = useLocation();


    const [isExistPix, setIsExistPix] = useState<boolean>(false);

    const [typeAnother, setTypeAnother] = useState<boolean>(false);

    const [keyPixType, setKeyPixType] = useState<keyPixTypeProp | 'none'>(user?.cpf?.length === 11 ? 'cpf' : 'cnpj');

    const [errorTypeKey, setErrorTypeKey] = useState<string>("");

    const [showPin, setShowPin] = useState(false)

    const [loading, setLoading] = useState(false);

    const [modalConfirmation, setOpenModalConfirmation] = useState(false)

    const [withdrawalRequested, setWithdrawalRequested] = useState(false)

    const [withDrawOtherValue, setWithDrawOtherValue] = useState<string>("0")

    const [withDrawOtherValueStatus, setWithDrawOtherValueStatus] = useState<string>("")

    const [modalPartialWithDraw, setModalPartialWithDraw] = useState(false)

    const [loadigPartialWithDraw, setLoadigPartialWithDraw] = useState(false)

    const MaskInputPix = (a: any): any => {
        if (keyPixType === 'cpf') {
            return CPFMaskCustom(a)
        }
        if (keyPixType === 'phone') {
            return CellphoneMaskCustom(a)
        }
        if (keyPixType === 'cnpj') {
            return CNPJMaskCustom(a)
        }
        if (keyPixType === 'uuid') {
            return KeyPixRandomMaskCustom(a)
        }
    }

    const HandleWithdraw = ({ keypix, value }: { keypix: string, value?: number }) => {
        if (value) setLoadigPartialWithDraw(true)
        setOpenModalConfirmation(false)
        if (!keyPixType || keyPixType === 'none') {
            setErrorTypeKey("Selecione o tipo de chave")
            return
        }
        setLoading(true)

        const pixInfo = {
            key: keypix,
            kind: keyPixType
        }

        StorageService.savePixKey(pixInfo)

        AuthService.requestCredits({
            pixInfo: pixInfo,
            value
        })
            .then(({ data }) => {
                setWithdrawalRequested(true)
            })
            .catch(() => toast("Erro durante a solicitação, tente novamente", {
                type: 'error'
            }))
            .finally(() => {
                setLoading(false)
                setLoadigPartialWithDraw(false)
            })
    }

    const NameClient = NameMask(user?.name ?? "")

    useEffect(() => {
        console.log(user)
        const pix_save = StorageService.getPixKey();
        console.log(pix_save)
        if (pix_save) {
            setKeyPixType(pix_save.kind)
            setValue('keypix', pix_save.key)
            setIsExistPix(true)
        } else {
            setValue('keypix', user?.cpf ?? '')
        }
    }, [user?.cpf])

    useEffect(() => {
        if (user && user?.credits <= 0) {
            navigate(-1)
        }
    }, [user?.credits])



    useEffect(() => {
        if (withdrawalRequested) {
            setTimeout(() => {
                if (pathname.includes('cliente')) {
                    navigate(RoutesPath.Clients.path)
                }
                if (pathname.includes("correspondente") || pathname.includes("venda")) {
                    navigate("/venda/meusaldo")
                }
            }, 3000)
        }
    }, [withdrawalRequested])

    const handleCloseModalOtherValue = () => {
        setModalPartialWithDraw(false)
        setWithDrawOtherValueStatus("")
        setWithDrawOtherValue("0")
    }

    return (
        <Grid container alignItems={"center"} mt={2} flexDirection={'column'} gap={4} p={2}>
            <Typography fontWeight="700" variant="h3">Olá, {NameClient.firstName} {NameClient.lastName}</Typography>
            <Grid border="1px solid black" borderRadius={2} container>
                <Grid p={2}>
                    <Typography fontWeight="600" variant="h4">Solicitação de saque</Typography>
                </Grid>
                <Divider />
                <Grid p={2} container gap={2}>
                    <Grid container justifyContent="space-between">
                        <Typography fontWeight="400" variant="h4">Saldo disponível</Typography>
                        <Typography fontWeight="400" variant="h4">{user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Typography fontWeight="400" variant="h4">Taxa de saque</Typography>
                        <Typography fontWeight="400" variant="h4">- {constants.pixTransferTax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ borderStyle: 'dashed' }} />
                <Grid container justifyContent="space-between" p={2}>
                    <Typography fontWeight="700" variant="h4">Valor total do saque</Typography>
                    <Typography fontWeight="700" variant="h4">{((user?.credits ?? 0) - constants.pixTransferTax).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                </Grid>
            </Grid>
            <Typography fontWeight="700" variant="h3">{isExistPix ? "CONFIRME SUA CHAVE PIX" : "CHAVE PIX PARA SAQUE"}</Typography>
            {
                isExistPix && !typeAnother ?
                    <Grid width="100%">
                        <Grid container justifyContent="space-between" alignItems={"center"}>
                            <Typography fontWeight="bold" variant="h3">
                                Tipo de chave PIX:
                            </Typography>
                            <Typography fontWeight="400" variant="h3">
                                {typesPix?.find(a => a.type === keyPixType)?.text}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems={"center"}>
                            <Typography fontWeight="bold" variant="h3">
                                Chave PIX:
                            </Typography>
                            <Typography fontWeight="400" variant="h3">
                                {typesPix?.find(a => a.type === keyPixType)?.text === "CPF" || typesPix?.find(a => a.type === keyPixType)?.text === "CNPJ" ? formatCPF(getValues('keypix')) : getValues('keypix')}
                            </Typography>
                        </Grid>
                        {user?.validationPix && user?.validationPix.status === 2 &&
                            <Grid container justifyContent="space-between" alignItems={"center"} mt={2}>
                                <Typography fontWeight="bold" variant="h4">
                                    Atenção:
                                </Typography>
                                <Typography fontWeight="400" variant="h5"  color={"red"}>
                                    Essa chave pix apresenta o seguinte erro: <br/> "{user?.validationPix?.errorDescription?.description as string}" <br/> Informe uma chave PIX válida para solicitar seu saque.
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    :
                    <>
                        <Typography fontWeight="400" variant="h3">
                            Informe sua chave pix cadastrada no seu <strong>{user?.cpf?.length === 11 ? "CPF" : "CNPJ"}: {formatCPF(user?.cpf ?? '00000000000')}</strong>
                        </Typography>
                        <Grid container justifyContent="space-between" alignItems={"center"}>
                            <Typography fontWeight="700" variant="h3">Tipo de chave PIX:</Typography>

                            <Select
                                required
                                value={keyPixType}
                                onChange={(event) => {
                                    setKeyPixType(event.target.value as keyPixTypeProp);
                                    setErrorTypeKey("");
                                    setValue("keypix", "")

                                    if (event.target.value === "cpf" || event.target.value === "cnpj") {
                                        setValue("keypix", user?.cpf as string)
                                    }
                                }}
                                sx={{ fontWeight: '500', minWidth: 150 }}
                            >

                                <MenuItem defaultChecked disabled value={"none"}>Selecionar</MenuItem>
                                {
                                    typesPix.filter((key) => {
                                        if (user?.cpf?.length === 11) {
                                            return key.type !== "cnpj"
                                        } else {
                                            return key.type !== "cpf"
                                        }
                                    }).map(({ text, type }) => (
                                        <MenuItem value={type} id={type}>{text}</MenuItem>

                                    ))
                                }
                            </Select>
                            <FormHelperText sx={{ color: 'red' }}>{errorTypeKey}</FormHelperText>
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems={"center"}>
                            <Typography fontWeight="700" variant="h3">Chave PIX:</Typography>
                            <OutlinedInput
                                disabled={keyPixType === "cpf" || keyPixType === "cnpj"}
                                sx={{ maxWidth: 200 }}
                                error={!!errors.keypix}
                                inputComponent={keyPixType !== 'email' ? MaskInputPix : undefined}
                                type={keyPixType === 'email' ? 'email' : 'text'}
                                {...register("keypix", {
                                    required: true,
                                    validate: (value) => {
                                        if (keyPixType === 'cpf' && !Validators.validateCPF(value)) {
                                            return "CPF inválido!"
                                        }
                                        if (keyPixType === 'email' && !validator.isEmail(value)) {
                                            return "E-mail inválido!"
                                        }
                                        if (keyPixType === 'phone' && !Validators.validateWhatsAppNumber(value)) {
                                            return "Telefone inválido!"
                                        }
                                        if (keyPixType === 'cnpj' && !Validators.validateCNPJ(value)) {
                                            return "CNPJ inválido!"
                                        }
                                    }
                                })}
                            />
                            <FormHelperText sx={{ color: 'red' }}>{errors.keypix?.message as string}</FormHelperText>
                        </Grid>
                    </>
            }


            <LoadingButton
                loading={loading}
                variant="contained" sx={{ p: 1, fontSize: '1rem', animation: `pulseWithoutBoxShadow 2s infinite` }}
                fullWidth
                onClick={() => setOpenModalConfirmation(true)}

            >
                SACAR VALOR TOTAL
            </LoadingButton>
            <LoadingButton
                loading={loading}
                variant="outlined" color="inherit"
                sx={{ p: 1, fontSize: '1rem' }}
                fullWidth
                onClick={() => setModalPartialWithDraw(true)}

            >
                SACAR VALOR PARCIAL
            </LoadingButton>
            {
                isExistPix &&
                <Button
                    variant="outlined" color="inherit"
                    sx={{ p: 1, fontSize: '1rem', textTransform: 'none' }}
                    fullWidth
                    onClick={() => {
                        if (typeAnother) {
                            let pix = StorageService.getPixKey()
                            setKeyPixType(pix?.kind as any)
                            setValue("keypix", pix?.key as string)
                        }
                        setTypeAnother(!typeAnother)
                    }}>

                    {typeAnother ? 'Usar a mais recente' : ' Informar outra chave pix'}
                </Button>
            }
            <Grid width="100%">
                <Button color="inherit" variant="outlined" sx={{ p: 1, fontSize: '1rem', maxWidth: 150 }} fullWidth onClick={() => navigate(-1)}>
                    Voltar
                </Button>
            </Grid>
            <PaymentMade
                show={withdrawalRequested}
                text='Saque foi solicitado com sucesso!'
            />
            <Dialog open={modalConfirmation} sx={{
                '.MuiDialog-paper': {
                    border: `2px solid ${theme().palette.primary.main}`
                }
            }}>
                <DialogTitle>
                    <Typography
                        fontWeight="500"
                        variant="h5"
                        textAlign="center">
                        <strong>CONFIRMAÇÃO DE CHAVE PIX</strong>
                        <br />
                        <i>(Conforme Lei 9.613/98)
                        </i>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        mt={2}
                        fontWeight="500"
                        variant="h5"
                        textAlign="center"
                    >{NameClient.firstName} {NameClient.lastName}, para solicitar seu saque é necessário que sua <strong>CHAVE PIX</strong> esteja vinculada ao seu próprio <br /><strong>{user?.cpf?.length === 11 ? "CPF" : "CNPJ"}: {formatCPF(user?.cpf as string)}</strong>.<br /><br />Você confirma que a <strong>chave pix cadastrada</strong> está <strong>vinculada a seu {user?.cpf?.length === 11 ? "CPF" : "CNPJ"}</strong>?</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} justifyContent={"center"}>
                        <Button variant="contained" onClick={handleSubmit(HandleWithdraw)}>Confirmo</Button>
                        <Button variant="outlined" sx={{
                            color: "GrayText",
                            borderColor: "GrayText"
                        }} onClick={() => setOpenModalConfirmation(false)}>Não</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={modalPartialWithDraw} sx={{
                '.MuiDialog-paper': {
                    border: `2px solid ${theme().palette.primary.main}`,
                    width: '100%'
                }
            }} onClose={() => {
                if (!loadigPartialWithDraw) {
                    handleCloseModalOtherValue()
                }
            }}>
                <DialogTitle>
                    <Typography
                        fontWeight="500"
                        variant="h5"
                        textAlign="center">
                        SACAR VALOR PARCIAL
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid display={"flex"} flexDirection={"column"} gap={2}>
                        <Grid container justifyContent="space-between">
                            <Typography fontWeight="400" variant="h5">Saldo disponível:</Typography>
                            <Typography fontWeight="400" variant="h5">{user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                        </Grid>

                        <Grid container justifyContent="space-between">
                            <Typography fontWeight="400" variant="h5">Taxa de saque:</Typography>
                            <Typography fontWeight="400" variant="h5" color={"red"}>- {constants.pixTransferTax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                        </Grid>

                        <TextField
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Qual o valor que deseja sacar?"
                            value={moneyMask(withDrawOtherValue)}
                            onChange={(e) => {
                                setWithDrawOtherValue(e.target.value)
                                const value = currencyToNumber(e.target.value)
                                const valueToRequest = round((user?.credits as number) - constants.pixTransferTax)
                                if (value > valueToRequest) {
                                    setWithDrawOtherValueStatus(`O valor parcial não pode ser maior que ${valueToRequest.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`)
                                } else if (value < (constants.minPixTransfer - constants.pixTransferTax)) {
                                    setWithDrawOtherValueStatus(`O valor parcial não pode ser menor do que: ${(constants.minPixTransfer - constants.pixTransferTax).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`)
                                } else {
                                    setWithDrawOtherValueStatus("")
                                }
                            }}
                            error={!!withDrawOtherValueStatus}
                            helperText={withDrawOtherValueStatus}
                        />

                        {!withDrawOtherValueStatus && <Grid container justifyContent="space-between">
                            <Typography fontWeight="400" variant="h5">Saldo restante:</Typography>
                            <Typography fontWeight="400" variant="h5">{((user?.credits as number) - constants.pixTransferTax - currencyToNumber(withDrawOtherValue)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                        </Grid>}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalOtherValue} disabled={loadigPartialWithDraw}>Cancelar</Button>
                    <LoadingButton
                        variant="contained"
                        loading={loadigPartialWithDraw}
                        disabled={!!withDrawOtherValueStatus || currencyToNumber(withDrawOtherValue) === 0 || loadigPartialWithDraw}
                        onClick={() => HandleWithdraw({ keypix: getValues("keypix"), value: currencyToNumber(withDrawOtherValue) })}
                    >
                        Sacar valor parcial
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default WithdrawPage;