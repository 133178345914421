import { Button, Checkbox, Dialog, Divider, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Select, Typography, colors } from "@mui/material"
import useBet from "context/bet";

import copy from 'copy-to-clipboard';

import PIXLOGO from "assets/imgs/pix.svg"
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import { moneyMask } from "utils/mask";
import { useEffect, useState } from "react";

import useSocket from "service/socket";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PaymentMade from "components/paymentMade";
import RoutesPath from "utils/routesBases";
import { BetService } from "service/bets";
import useAuth from "context/auth";
import theme from "theme";
import moment from "moment";
import CreditAdded from "components/creditAdded";

const PaymentPage = () => {

    const { socket } = useSocket();

    const navigate = useNavigate();

    const { selectDraw, payment, order, purchaseFlow, sellerUuid, clientThatIndicate, ResetBet } = useBet();
    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Revendedor

    const [paymentMade, setPaymentMade] = useState(false);

    const [creditAdded, setCreditAdded] = useState(false);

    const [now, setNow] = useState(new Date())

    const [recibo, setrecibo] = useState<any>({});

    const { user } = useAuth();

    const { state } = useLocation()

    const [disabledSelect, setDisabledSelect] = useState(false)

    useEffect(() => {
        console.log(payment)
        if (payment) {
            // socket.emit('bind', {
            //     case: 'paid-bet',
            //     kind: 'bet',
            //     target: payment?.bet?.uuid ?? ""
            // })
            // socket.on('notify', (data) => {
            //     setPaymentMade(true)
            //     setrecibo(data?.certificateInfo)
            // })
        }
    }, [payment])

    useEffect(() => {
        console.log(state)
    }, [state])


    useEffect(() => {
        if (paymentMade) {
            setTimeout(() => {
                navigate(RouterPath.bet.recibo2.path + '/' + payment?.bet?.uuid, {
                    state: recibo
                })
            }, 3000)
        }
    }, [paymentMade])

    useEffect(() => {
        const intervalPaid = setInterval(() => {
            BetService.inspect(payment?.bet?.uuid ?? "")
                .then(({ data }) => {
                    if (data?.status === 4) {
                        setPaymentMade(true);
                        setrecibo(data?.certificateInfo);
                        clearInterval(intervalPaid);
                    } else if (data?.status === 5 && data?.payment?.status === 5) {
                        setCreditAdded(true)
                        clearInterval(intervalPaid);
                    } else {
                        setNow(new Date())
                    }
                })
                .catch((err) => console.log(err));
        }, 3000);

        return () => clearInterval(intervalPaid);
    }, []);

    const sendCode = async (type: string) => {
        setDisabledSelect(true)
        if (type === "sms" || type === "whatsapp") {
            await BetService.sendCopyAndPast({
                send_to: type,
                bet_id: payment?.bet.id as number
            }).then(() => {
                console.log("enviou")
            })
        }
    }

    return (
        <Grid container gap={2}>
            {/* <img src={selectDraw?.banner} alt="banner" width="100%" /> */}
            <Grid p={2} width="100%" container gap={3}>
                <Grid border="1px solid #000" width="100%" borderRadius={2}>
                    <Grid p={2}>
                        <Typography>Pagar seu pedido:</Typography>
                    </Grid>
                    <Divider />
                    <Grid p={2} container alignItems="center" justifyContent="space-between">
                        <Typography>{order?.group} apostas</Typography>
                        <Typography>{moneyMask(String(order?.value.toFixed(2)))}</Typography>
                    </Grid>
                    {
                        (payment?.bet?.paymentInfo?.creditsUsed ?? 0) > 0 &&
                        <Grid p={2} container alignItems="center" justifyContent="space-between">
                            <Typography maxWidth={200}>(-) Utilização de saldo da sua conta</Typography>
                            <Typography>{moneyMask(String((payment?.bet?.paymentInfo?.creditsUsed ?? 0).toFixed(2)))}</Typography>
                        </Grid>
                    }
                    <Divider style={{ borderStyle: 'dashed' }} />
                    <Grid p={2} container alignItems="center" justifyContent="space-between">
                        <Typography fontWeight={"bold"}>Valor total a ser pago</Typography>
                        <Typography>{moneyMask(String(payment?.bet?.paymentInfo?.valueToPay.toFixed(2) ?? "0"))}</Typography>
                    </Grid>
                </Grid>
                <Divider />
                {Math.trunc(moment.duration(moment(selectDraw?.closeIn).diff(moment(now))).asMinutes()) <= 20 && <>
                    <Grid container gap={3}>
                        <Typography><strong style={{
                            color: 'red',
                            textDecoration: 'underline'
                        }}>AVISO:</strong> Para comprar as apostas, <span style={{ color: 'red' }}>efetue o pagamento até às {moment(selectDraw?.closeIn).add('minutes', 15).format('HH:mm')} hrs</span>.</Typography>

                        <Typography>
                            Obs: Caso o pagamento seja efetuado <span style={{ color: 'red' }}>após às {moment(selectDraw?.closeIn).add('minutes', 15).format('HH:mm')} hrs, suas apostas não serão validados</span>, o seu pagamento via PIX será convertido em adição de saldo, o valor pago via PIX ficará visível em <Link to="/cliente?movements=true">Saldo Disponível</Link>.
                        </Typography>
                    </Grid>
                    <Divider />
                </>}
                <Grid container gap={3} sx={{ opacity: 1 }}>
                    <Typography fontWeight={"bold"} variant="h4">Forma de pagamento</Typography>
                    <Grid container alignItems="center" gap={1}>
                        <img src={PIXLOGO} width={20} />
                        <Typography fontWeight={"bold"}>Pagar via PIX</Typography>
                    </Grid>
                    <Typography>Para pagar as apostas, copie e cole a chave PIX no aplicativo do seu banco. </Typography>
                    <Grid container p={2} bgcolor="#0000000A" alignItems="center" justifyContent="space-between">
                        <Typography width="calc(100% - 45px)" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {payment?.pixCopyAndPaste}
                        </Typography>
                        <IconButton onClick={() => {
                            toast("Chave copiada!", { type: 'success' })
                            copy(payment?.pixCopyAndPaste ?? "")
                        }}>
                            <FiCopy color={theme().palette.secondary.main} />
                        </IconButton>
                    </Grid>
                    <Button
                        sx={{
                            mt: -3,
                            width: "100%",
                            textAlign: "center",
                            textTransform: "none"
                        }}
                        color="secondary"
                        startIcon={<FiCopy />}
                        onClick={() => {
                            toast("Chave copiada!", { type: 'success' })
                            copy(payment?.pixCopyAndPaste ?? "")
                        }}
                    >
                        Clique aqui para copiar o código PIX
                    </Button>

                    <Grid container alignItems="center" justifyContent={'center'}>
                        <Typography>
                            Ou escaneie o QRCODE para pagar as apostas
                        </Typography>
                        {
                            payment?.qrCode?.value &&
                            (payment?.qrCode?.kind === "base64" ?
                                <img src={"data:image/png;base64," + payment.qrCode.value} width={'100%'} style={{ maxWidth: 200 }} /> :
                                <img src={payment.qrCode.value} width={'100%'} />)
                        }
                    </Grid>

                    {!disabledSelect &&
                        <Grid container>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    backgroundColor: "#25D366",
                                    '&:hover': {
                                        backgroundColor: "#199347"
                                    },
                                    textTransform: "inherit"
                                }}
                                onClick={() => {
                                    sendCode("whatsapp")
                                }}
                            >Se preferir, clique para {purchaseFlow === "saller" ? "enviar" : "receber"} o código PIX {purchaseFlow === "saller" ? "para o WhatsApp do cliente" : "em seu WhatsApp"}</Button>
                        </Grid>}
                    <Grid container justifyContent="center" mt={2} >
                        <Button color="inherit" variant="outlined" size="large" onClick={() => {
                            ResetBet();
                            navigate(purchaseFlow === 'saller' ? '/venda' : `/cliente/concurso/?${(sellerUuid ? `s=${sellerUuid}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}` : '')}`)
                        }} sx={{
                            color: 'GrayText',
                            borderColor: 'GrayText'
                        }}>
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </Grid >
            <PaymentMade
                show={paymentMade}
            />
            <CreditAdded
                show={creditAdded}
            />
        </Grid >
    )
}

export default PaymentPage;