import { Button, Checkbox, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, OutlinedInput, Radio, Typography, useTheme } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FaPix, FaMoneyBill } from "react-icons/fa6";
import { CPFMaskCustom, CellphoneMaskCustom } from "components/mask";
import useBet from "context/bet";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BetService } from "service/bets";
import { Validators } from "utils/validators";
import AlertAwaitAuthorizePaymente from "./awaitIdentityConfirm";
import useAuth from "context/auth";
import { LoadingButton } from "@mui/lab";
import RoutesPath from "utils/routesBases";
import { formatCurrency, moneyMask, round } from "utils/mask";
import useQuery from "hooks/useQuery";
import { WhatsApp } from "@mui/icons-material";
import PaymentMade from "components/paymentMade";
import { AuthService } from "service/auth";
import { AwaitWhatsAppConfirmation } from "./awaitWhatsappConfirmation";
import { Bet, PaymentProps } from "types/bet";

const OrderPrizeDraw = () => {

    const { user, tokenClient, Authenticate } = useAuth();

    const { palette } = useTheme()

    const location = useLocation()

    const { GetPredicationsNumber, luckyNumbersGroups, setPayment, selectDraw, seller,
        payment, order, purchaseFlow, favoriteNumber, clientThatIndicate, ResetBet, sellerUuid, hoursToShowStamp,
        calcDiscount } = useBet();

    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Revendedor

    const [loading, setLoading] = useState(false);
    const [loadingFindClient, setLoadingFindClient] = useState(false);

    const [canContinue, setCanContinue] = useState(false);

    const [showBirthDate, setshowBirthDate] = useState(false);
    const [birthday, setBirthday] = useState<Moment | null>(null);
    const [showPaymentAlert, setShowPaymentAlert] = useState<null | { type: 'client' | 'saller' }>(null);

    const [client, setClient] = useState<any | null>(null);
    const [Fakeclient, setClientFake] = useState<any | null>(null);

    const [formPayment, setFormPayment] = useState<"PIX" | "MONEY" | "" | null>(null);

    const [phoneIsDigited, setPhoneIsDigited] = useState(false);

    const [paymentInCash, setPaymentInCash] = useState(false)

    const [openWhatsappConfirmation, setWhatsappConfirmation] = useState(false)

    const navigate = useNavigate();

    const refCPFInput = useRef<HTMLInputElement | null>(null);

    const [firstPurchaseCompleted, setFirstPurchaseCompleted] = useState(false)

    const inputsRef = React.useRef<(HTMLInputElement | null)>(null);


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues
    } = useForm<{ cpf: string, phone: string }>()

    const handleSubmitClient = async ({ cpf, phone }: { cpf: string, phone: string }) => {
        setLoading(true)
        const birthdaydate = birthday ? birthday.format("YYYY-MM-DD") : undefined
        const CPF_Cliente = (purchaseFlow === 'client' && tokenClient) ? (user?.cpf ?? '') : cpf
        BetService.createClient({ cpf: CPF_Cliente, phone, birthday: birthdaydate })
            .then(({ data }) => {
                if (data?.id) {
                    if (!tokenClient) {
                        AuthService.clientLogin({
                            cpf: CPF_Cliente.replace(/\D/g, "")
                        })
                            .then(({ data }) => {
                                if (data) {
                                    Authenticate(data, 'client')
                                }
                            })
                    }
                    CreateBet({ userId: data?.id })
                    return
                }
                toast(data?.message ?? "Erro ao criar cliente!", { type: "error" })
            })
            .catch(err => {
                setLoading(false)
                if (err?.response?.data?.message?.error === "access_denied" || (err?.response?.status === 404 && err?.response?.data?.service !== 'getPerson')) {
                    setshowBirthDate(true)
                    inputsRef.current?.focus();
                    return
                }
                toast(err?.response?.data?.message ?? "Erro ao criar cliente!", { type: "error" })
            })
    }

    const CreateBet = ({ userId }: { userId: number }) => {
        const DigitizedNumbers = GetPredicationsNumber()
        const BetNumbers: { wasDigited: boolean, number: string }[] = []

        DigitizedNumbers?.map((numbers) => {
            BetNumbers.push({
                wasDigited: true,
                number: String(numbers)
            })
        })

        if (order?.group) {
            luckyNumbersGroups[order.group].map((numbers) => {
                BetNumbers.push({
                    wasDigited: false,
                    number: String(numbers)
                })
            })
        }
        BetService.bet({
            clientId: userId,
            sellerId: seller?.uuid ?? "",
            paymentInCash,
            prizeDrawId: selectDraw?.id ?? 0,
            numbers: BetNumbers,
            favoriteNumber,
            clientThatIndicate: clientThatIndicate,
            cardId: location?.state?.card?.id
        }, purchaseFlow === 'saller' ? true : false)
            .then(({ data }) => {
                if (data) {
                    setPayment(data)
                    if (data?.bet?.status === 4) {
                        setFirstPurchaseCompleted(true)
                        redirectFirstPurchase(data)
                        return
                    }
                    if (data?.bet?.status === 3) {
                        setWhatsappConfirmation(true)
                        return
                    }
                    navigate(RouterPath.bet.payment2.path + '/' + data?.bet?.uuid, {
                        state: {
                            ...data,
                            phone_client: getValues("phone")
                        }
                    })
                }
            })
            .catch((err) => {
                toast(err?.response?.data?.message ?? 'Erro: contate o suporte caso o problema persista!', { type: 'error' })
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (tokenClient && purchaseFlow === 'client') {
            setCanContinue(true)
            setClient(user)
        }
    }, [tokenClient, purchaseFlow])

    const redirectFirstPurchase = (data: any) => {
        setTimeout(() => {
            navigate(RouterPath.bet.recibo2.path + '/' + data?.bet?.uuid, {
                state: { ...data.bet.certificateInfo, betId: data?.bet?.id },
            })
            setFirstPurchaseCompleted(false)
        }, 3000)
    }



    const FindClient = async (cpf: string) => {
        refCPFInput?.current?.blur();
        setLoadingFindClient(true)
        setCanContinue(true)
        setBirthday(null)
        setshowBirthDate(false)
        setPhoneIsDigited(false)
        setClientFake(null)
        setClient(null)
        setValue('phone', '')
        BetService.findClient(cpf)
            .then(({ data }) => {
                if (data) {
                    setClient(data)
                }
            })
            .catch((err) => {
                setClientFake({ id: 1 })
            })
            .finally(() => {
                setLoadingFindClient(false)
                setCanContinue(true)
                setshowBirthDate(false)
                setBirthday(null)
            })
    }

    const totalToPay = order?.value as number;
    const clientCredits = (client?.credits ?? 0);
    const clientGuessCredits = (client?.guessCredits ?? 0)
    let totalToPayInPix = 0;
    let totalGuessCreditsUsed = 0;

    if (clientGuessCredits > 0) {
        if (clientGuessCredits >= totalToPay) {
            totalToPayInPix = 0
            totalGuessCreditsUsed = totalToPay
        } else {
            const newValueToPay = totalToPay - clientGuessCredits
            if (clientCredits >= newValueToPay) {
                totalToPayInPix = 0
            } else {
                totalToPayInPix = newValueToPay - clientCredits
            }
            totalGuessCreditsUsed = clientGuessCredits
        }
    } else {
        totalToPayInPix = clientCredits >= totalToPay ? 0 : totalToPay - clientCredits;
    }

    let totalCreditsUsed = 0;
    const newValueToPay = totalToPay - totalGuessCreditsUsed

    if (clientCredits === 0) {
        totalCreditsUsed = 0;
    } else if (clientCredits >= newValueToPay) {
        totalCreditsUsed = newValueToPay;
    } else {
        totalCreditsUsed = clientCredits;
    }

    //calc seller

    const sellerGuessCredits = (user?.guessCredits ?? 0)
    let totalGuessCreditsUsedBySeller = 0;
    let totalCreditsUsedBySeller = 0;

    if (sellerGuessCredits > 0) {
        if (sellerGuessCredits >= totalToPay) {
            totalGuessCreditsUsedBySeller = totalToPay
        } else {
            totalCreditsUsedBySeller = totalToPay - sellerGuessCredits
            totalGuessCreditsUsedBySeller = sellerGuessCredits
        }
    } else {
        totalCreditsUsedBySeller = totalToPay;
    }

    useEffect(() => {
        setFormPayment('PIX')
    }, [totalToPayInPix, totalToPay])


    const renderDescription = () => {
        if ((user?.guessCredits ?? 0) > totalToPay) {
            return "quero pagar essa aposta utilizando meu CRÉDITO BONIFICADO PARA PAGAMENTO DE APOSTAS"
        } else if (totalCreditsUsedBySeller > 0 && totalGuessCreditsUsedBySeller > 0) {
            return "quero pagar essa aposta utilizando meu CRÉDITO BONIFICADO PARA PAGAMENTO DE APOSTAS e meu SALDO DISPONÍVEL"
        } else if ((user?.credits ?? 0) > totalToPay) {
            return "quero pagar essa aposta utilizando meu SALDO DISPONÍVEL"
        } else {
            return "quero pagar essa aposta utilizando meu CRÉDITO BONIFICADO PARA PAGAMENTO DE APOSTAS e meu SALDO DISPONÍVEL"
        }
    }


    return (
        <Container>
            <Grid mt={2} container gap={1} minHeight={300} alignContent={'flex-start'}>
                {(purchaseFlow === 'saller') || (purchaseFlow === 'client' && !tokenClient) ? <Grid container gap={1} alignContent={'start'}>
                    <Typography fontWeight={"bold"}>Informe {purchaseFlow === 'client' ? "seu CPF" : 'o CPF do seu cliente'}</Typography>
                    <FormControl fullWidth>
                        <OutlinedInput
                            autoFocus
                            error={!!errors.cpf}
                            placeholder="000.000.000-00"
                            inputComponent={CPFMaskCustom}
                            sx={{ fontSize: 20 }}
                            inputRef={refCPFInput}
                            disabled={loading}
                            typeof="number"
                            endAdornment={<InputAdornment position="end">{loadingFindClient ? <CircularProgress /> : ''}</InputAdornment>}
                            fullWidth
                            {...register("cpf", {
                                required: true,
                                onChange: (event) => {
                                    const value = event?.target?.value?.replace(/\D/g, '')
                                    if (value?.length === 11) {
                                        FindClient(value)
                                    }
                                },
                                validate: (value) => {
                                    if (!Validators.validateCPF(value)) {
                                        return "CPF inválido!"
                                    }
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.cpf?.message as string}</FormHelperText>
                    </FormControl>
                </Grid> : <></>}
                {
                    showBirthDate &&
                    <>
                        <Typography
                            fontWeight="bold"
                            variant="h5"
                        >
                            Informe {purchaseFlow === 'client' ? "sua data de Nascimento" : 'a data de Nascimento do seu cliente'}
                        </Typography>
                        <DesktopDatePicker
                            inputRef={(el) => inputsRef.current}
                            sx={{ fontSize: 20, width: '100%' }}
                            format="DD/MM/YYYY"
                            autoFocus
                            value={birthday}
                            onChange={(event) => setBirthday(event)}
                        />
                        {/* <FormHelperText sx={{ color: 'red' }}>{(showBirthDate && !birthday) ? (purchaseFlow === 'client' ? "sua data de Nascimento" : 'a data de Nascimento do seu cliente') : ''}</FormHelperText> */}
                    </>
                }
                <Typography fontWeight={"bold"}>Informe {purchaseFlow === 'client' ? "seu WhatsApp" : 'o WhatsApp do seu cliente'}</Typography>
                <OutlinedInput
                    autoFocus={(purchaseFlow === 'client' && !!tokenClient)}
                    error={!!errors.phone}
                    sx={{ fontSize: 20 }}
                    placeholder="(00) 0 0000-0000"
                    inputComponent={CellphoneMaskCustom}
                    fullWidth
                    {...register("phone", {
                        onChange: (event) => {
                            if (event?.target?.value?.length === 16) {
                                setPhoneIsDigited(true)
                            } else {
                                setPhoneIsDigited(false)
                            }
                        },
                        required: client ? false : true,
                        validate: (value) => {
                            if (!Validators.validateWhatsAppNumber(value)) {
                                return "WhatsApp invalido!"
                            }
                            else {
                                setPhoneIsDigited(true)
                            }
                        }
                    })}
                    startAdornment={
                        <InputAdornment position="start">
                            <WhatsApp color="success" />
                        </InputAdornment>
                    }
                />
                <FormHelperText sx={{ color: 'red' }}>{errors.phone?.message as string}</FormHelperText>
                {purchaseFlow === "saller" && (user?.credits as number + (user?.guessCredits ?? 0)) >= (order?.value as number) && phoneIsDigited && client &&
                    <FormControlLabel
                        control={<Checkbox
                            color="primary"
                            checked={paymentInCash}
                            onChange={(e) => setPaymentInCash(e.target.checked)} />}
                        label={
                            <Typography color={!paymentInCash ? 'GrayText' : 'inherit'}>
                                Eu, {user?.name} {renderDescription()}
                            </Typography>
                        } />
                }
                <Divider style={{ borderBottomWidth: 'thin' }} />
                {((purchaseFlow === 'saller' && client) || (purchaseFlow === 'client' && client && (totalCreditsUsed > 0 || totalGuessCreditsUsed > 0)) || (client?.firstPurchase && String(moment(selectDraw?.prizeDrawAt).locale('pt-br').format("HH:mm"))?.toUpperCase() === hoursToShowStamp && order?.group === 10) || !client) && phoneIsDigited ?
                    <>
                        <Typography variant="h3">{client?.name}</Typography>
                        <Grid border="1px solid #000" width="100%" borderRadius={2}>
                            <Grid p={2}>
                                <Typography>Pagar seu pedido:</Typography>
                            </Grid>
                            <Grid p={2} container alignItems="center" justifyContent="space-between">
                                <Typography>{order?.group} apostas</Typography>
                                <Typography>{moneyMask(String(order?.value?.toFixed(2)))}</Typography>
                            </Grid>
                            {(totalCreditsUsed > 0 || paymentInCash) && <Divider />}
                            {
                                purchaseFlow === 'client' && (totalCreditsUsed > 0) && !((client?.firstPurchase || !client) && String(moment(selectDraw?.prizeDrawAt).locale('pt-br').format("HH:mm"))?.toUpperCase() === hoursToShowStamp && order?.group === 10) ?
                                    <Grid p={2} container alignItems="center" justifyContent="space-between">
                                        <Typography maxWidth={200}>(-) Utilização de saldo da sua conta</Typography>
                                        <Typography>{totalCreditsUsed?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                    </Grid>
                                    : <></>
                            }
                            {
                                purchaseFlow === 'client' && (totalGuessCreditsUsed > 0) && !((client?.firstPurchase || !client) && String(moment(selectDraw?.prizeDrawAt).locale('pt-br').format("HH:mm"))?.toUpperCase() === hoursToShowStamp && order?.group === 10) ?
                                    <Grid p={2} container alignItems="center" justifyContent="space-between">
                                        <Typography maxWidth={200}>(-) Utilização de bônus de aposta</Typography>
                                        <Typography>{totalGuessCreditsUsed?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                    </Grid>
                                    : <></>
                            }
                            {
                                (client?.firstPurchase || !client) && String(moment(selectDraw?.prizeDrawAt).locale('pt-br').format("HH:mm"))?.toUpperCase() === hoursToShowStamp && order?.group === 10 &&
                                <Grid p={2} container alignItems="center" justifyContent="space-between">
                                    <Typography maxWidth={200}>(-) Bônus de primeira compra</Typography>
                                    <Typography>{moneyMask(String(((selectDraw?.numberPrice ?? 0) * (order?.group ?? 0))?.toFixed(2)))}</Typography>
                                </Grid>
                            }
                            {
                                purchaseFlow === "saller" && paymentInCash && totalGuessCreditsUsedBySeller > 0 &&
                                <Grid p={2} container alignItems="center" justifyContent="space-between">
                                    <Typography maxWidth={200} fontWeight={"bold"} textAlign={"justify"}>(-) Utilização de CRÉDITO BONIFICADO PARA PAGAMENTO DE APOSTAS disponível de {user?.name}</Typography>
                                    <Typography fontWeight={"bold"}>- {moneyMask(String(totalGuessCreditsUsedBySeller.toFixed(2)))}</Typography>
                                </Grid>
                            }
                            {
                                purchaseFlow === "saller" && paymentInCash && totalCreditsUsedBySeller > 0 &&
                                <Grid p={2} container alignItems="center" justifyContent="space-between">
                                    <Typography maxWidth={200} fontWeight={"bold"} textAlign={"justify"}>(-) Utilização de SALDO DISPONÍVEL de {user?.name}</Typography>
                                    <Typography fontWeight={"bold"}>- {moneyMask(String(totalCreditsUsedBySeller.toFixed(2)))}</Typography>
                                </Grid>
                            }
                            <Divider style={{ borderStyle: 'dashed' }} />
                            <Grid p={2} container alignItems="center" justifyContent="space-between">
                                <Typography fontWeight={paymentInCash ? "400" : "bold"}>Valor a ser pago via PIX</Typography>
                                {
                                    (client?.firstPurchase || !client) && String(moment(selectDraw?.prizeDrawAt).locale('pt-br').format("HH:mm"))?.toUpperCase() === hoursToShowStamp && order?.group === 10 ?
                                        <Typography>{(0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                        :
                                        purchaseFlow === 'saller' ?
                                            <Typography>{moneyMask(String((paymentInCash ? 0 : order?.value as number).toFixed(2)))}</Typography> :
                                            <Typography>{moneyMask(String(totalToPayInPix.toFixed(2)))}</Typography>
                                }
                            </Grid>
                        </Grid>
                        {/* <Divider /> */}
                    </> : <></>
                }
                {!openWhatsappConfirmation &&
                    <Grid width="100%">
                        <LoadingButton
                            sx={{ mt: 2 }}
                            disabled={!canContinue || (purchaseFlow === 'saller' &&
                                !formPayment && (client || Fakeclient)) || !phoneIsDigited ||
                                (showBirthDate && !birthday)}
                            loading={loading}
                            variant="contained" size="large" fullWidth onClick={handleSubmit(handleSubmitClient)}>
                            Continuar
                        </LoadingButton>
                    </Grid>
                }
            </Grid>
            {
                !openWhatsappConfirmation &&
                <Grid container justifyContent="center" mt={2} paddingBottom={2}>
                    <Button color="inherit" variant="outlined" size="large" onClick={() => {
                        ResetBet();
                        navigate(purchaseFlow === 'saller' ? '/venda' : `/cliente/concurso/?${(sellerUuid ? `s=${sellerUuid}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}` : '')}`)
                    }}>
                        Cancelar
                    </Button>
                </Grid>
            }

            <AlertAwaitAuthorizePaymente
                purchaseFlow={showPaymentAlert?.type}
                open={!!showPaymentAlert}
                saller={showPaymentAlert?.type === 'saller' ? {
                    betId: payment?.bet?.uuid ?? ""
                } : null}
                client={showPaymentAlert?.type === 'client' ? {
                    betId: payment?.bet?.uuid ?? "",
                    clientId: payment?.bet?.clientId ?? null
                } : null}
            />

            <AwaitWhatsAppConfirmation
                open={openWhatsappConfirmation}
                bet={payment?.bet as Bet}
            />

            <PaymentMade
                show={firstPurchaseCompleted}
            />
        </Container>
    )
}

export default OrderPrizeDraw;