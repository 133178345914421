import { InputsFormsRegister } from "types";
import api from "../api"
import { keyPixTypeProp } from "components/withdraw";
import StorageService from "service/storage";
import { Utils } from "utils";

export class AuthService {
    static register(data: InputsFormsRegister) {
        return api.post('/users/sellers', data)
    }
    static login(cpf: string, password: string, role: number) {
        return api.post('/users/login', {
            cpf,
            password,
            role
        })
    }
    static confirmIdentity(userId: number, token: string) {
        return api.patch('/users/sellers/confirm-identity', {
            userId,
            token
        })

    }
    static me() {
        return api.get('/users/me')
    }

    static clientMe() {
        return api.get('/clients/me')
    }

    static clientRequestLogin(cpf: string) {
        return api.post(`/clients/request-login/${cpf}`)
    }

    static clientLogin(data: {
        "id"?: number | string,
        "token"?: string,
        "cpf"?: string
    }) {
        return api.post(`/clients/login`, data)
    }

    static requestPINCredits() {
        return api.post("/credits/withdraw/request-token")
    }

    static requestCredits(data: {
        pixInfo: { key: string, kind: keyPixTypeProp },
        value?: number
    }) {
        return api.post(`/credits/withdraw`, data)
    }

    static sellerExist(cpf: string) {
        return api.get(`/users/sellers/exist/${cpf}`)
    }

    static logout() {
        let pathname = window.location.pathname
        let isClient = pathname.includes("cliente")
        let isGeneral = pathname.includes("geral")
        let key = isClient ? Utils.storageLocales.NFVtokenClient : Utils.storageLocales.NFVtoken
        StorageService.deleteToken(key)
        StorageService.deletePixKey()
        window.location.replace(isClient ? "/cliente/login" : isGeneral ? "/geral/login" : "/venda/login")
    }
}