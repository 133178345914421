import { Avatar, Button, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import Card from "components/cards";
import { PredictionInput } from "components/prediction/input";
import useBet, { NumbersGroups, PredictionPros, PredictionsProps } from "context/bet";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { BetService } from "service/bets";
import { sendToShow } from "utils";
import ShowNumbersLuck from "./showNumbers";

import { LoadingButton } from "@mui/lab";

import { NumbersOptions } from "./numbersOptions";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import RoutesPath from "utils/routesBases";

const FavoriteNumber = () => {

    const {
        favoriteNumber,
        setFavoriteNumber,
        predictions,
        setPrediction,
        setLuckNumbers,
        luckyNumbers,
        selectDraw,
        GetPredicationsNumber,
        purchaseFlow,
        order
    } = useBet();

    const navigate = useNavigate()
    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Revendedor
    const [canContinue, setCanContinue] = useState(false);
    const [loadingGenerate, setLoadingGenerate] = useState(false);

    const [showModalNumbersLuck, setShowModalNumbersLuck] = useState<NumbersGroups | null>(null);

    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    const useRow = useMediaQuery("(max-width:410px)")

    const [alreadyGeneratedNumbers, setAlreadyGeneratedNumbers] = useState(false)

    const handlePredictionChange = (key: string, prediction: PredictionPros[]) => {
        setPrediction({ ...predictions, [key]: prediction });
    };

    const [loadingRandomNumbers, setLoadingRandomNumbers] = useState(false)

    const [activeInput, setActiveInput] = useState<null | number>(null)


    useEffect(() => {
        let can_continue = true
        if (predictions) {
            for (const key in predictions) {
                if (predictions.hasOwnProperty(key)) {
                    const array: PredictionPros[] = predictions[key] as PredictionPros[]
                    if (array && array?.length < 4) {
                        can_continue = false

                    }
                    for (const keyArr in array) {
                        if (array[keyArr]?.number === null || array[keyArr]?.number === undefined) {
                            can_continue = false

                        }
                    }
                }
            }
        } else {
            can_continue = false
        }

        if (Object.entries(predictions ?? {})?.length < (order?.group as number)) {
            can_continue = false
        }
        setCanContinue(can_continue)
    }, [predictions])

    const { palette } = useTheme();

    useEffect(() => {
        sendToShow("favorite_predictions", 'start')
        setTimeout(() => {
            if (!inputsRef?.current[0]?.value) {
                inputsRef?.current[0]?.focus()
            }
        }, 100)
    }, [favoriteNumber])


    useEffect(() => {
        if (canContinue && luckyNumbers === null) {
            sendToShow("generate_luck_numbers")
        }
        if (luckyNumbers && inputsRef) {
            sendToShow("buy_prediction")
        }
    }, [canContinue, luckyNumbers, inputsRef])


    const generateNumber = async (): Promise<any> => {
        setLoadingRandomNumbers(true)
        let numbers: string[] = []
        await BetService.getRandomNumber(selectDraw?.id as number, order?.group as number).then(({
            data
        }) => {
            numbers = data.numbers
        })
        let _predict: PredictionsProps = {}
        for (let i = 0; i < (order?.group as number); i++) {
            let key = `predication${i + 1}`
            let exist = (predictions ?? {} as PredictionsProps)[key]

            if (!exist || (exist && exist?.length === 0)) {
                let split = numbers[i].split("")
                let newPredict: PredictionPros[] = []
                for (let x = 0; x < split.length; x++) {
                    newPredict.push({
                        id: x,
                        number: Number(split[x])
                    })
                }
                _predict = {
                    ..._predict,
                    [key]: newPredict
                }
            } else {
                if (exist && exist?.length > 0 && exist?.length < 4) {
                    console.log(key, exist)
                    let split = numbers[i].split("")
                    let newPredict: PredictionPros[] = []
                    for (let x = 0; x < split.length; x++) {
                        let find = exist.find((item) => item.id === x)
                        if (find) {
                            newPredict.push(find)
                        } else {
                            newPredict.push({
                                id: x,
                                number: Number(split[x])
                            })
                        }
                    }
                    _predict = {
                        ..._predict,
                        [key]: newPredict
                    }
                }
            }
        }
        setPrediction({ ...predictions, ..._predict });
        setLuckNumbers(null)
        // setAlreadyGeneratedNumbers(true)
        setLoadingRandomNumbers(false)
        // focusNext()
    }

    const focusNext = () => {
        const incompleteList = Object.entries(predictions ?? {}).filter(([key, array]) => (array ?? []).length < 4)
        const incomplete = incompleteList.sort((a, b) => Number(a[0].split('predication')[1]) - Number(b[0].split('predication')[1]))[0]
        console.log(incomplete)
        if (incomplete) {
            let predication_index = Number((incomplete[0] as unknown as string).split("predication")[1])
            let index: number | null = null
            for (let i = 0; i < 4; i++) {
                if (!(incomplete[1] ?? []).find((item) => item.id === i) && index === null) {
                    index = i
                }
            }
            console.log(index)
            const refPosition = ((index as number) + (predication_index - 1) * 4)

            console.log(refPosition)
            inputsRef.current[refPosition]?.focus()
        }
    }

    const renderNamePrize = (name: string, color: string, isFavorite: boolean = false) => {
        if (isFavorite) {
            return (
                <p style={{
                    display: 'inline',
                    textDecoration: 'underline',
                    textDecorationColor: '#bab7b6',
                    margin: 0,
                }}>
                    <span style={{
                        fontWeight: '700',
                        color: color,
                    }}>
                        {name}
                    </span>
                </p>
            )
        }
        return (
            <p style={{
                display: 'inline',
                fontWeight: '700',
                color: color,
                margin: 0,
            }}>{name}</p>
        )
    }

    return (
        <Grid container gap={2} id="favorite_number" position={"relative"}>
            {!canContinue && Object.values(predictions ?? {}).filter((item) => (item as PredictionPros[]).length < 4).length < (order?.group as number) &&
                <LoadingButton
                    disabled={alreadyGeneratedNumbers || loadingRandomNumbers}
                    loading={loadingRandomNumbers}
                    onClick={generateNumber}
                    variant="outlined"
                    sx={{
                        backgroundColor: 'transparent',
                        backdropFilter: 'blur(3px)',
                        fontWeight: 'bold',
                        position: 'fixed',
                        bottom: 20,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: 300,
                        zIndex: 100
                    }}
                >Se preferir, clique aqui para preencher automaticamente as apostas em branco</LoadingButton>}
            <Grid container gap={4} minWidth={300}>
                <Typography width="100%" variant="h4" textAlign='center' fontWeight="400" id="favorite_predictions">
                    <strong>Preencha suas {order?.group} apostas</strong> <br /> com os números de sua preferência.
                    <br /><br /> {renderNamePrize("QUADRA", 'rgba(0, 82, 0, .9)')} | {renderNamePrize("TERNO", 'rgba(0, 82, 0, .8)')} | {renderNamePrize("DUQUE", 'rgba(0, 82, 0, .8)')} | {renderNamePrize("UNIDADE", palette.primary.main, true)}
                </Typography>
                {
                    Array(order?.group).fill(null).map((_, predication_index) => {
                        const beforeKey = `predication${predication_index}`
                        let disabledNext = false
                        if (predication_index > 0) {
                            if (((predictions ?? {})[beforeKey] ?? [])?.length < 4) {
                                disabledNext = true
                            }
                        }
                        return (
                            <Grid key={predication_index} container justifyContent={"space-around"} alignItems="center" direction={useRow ? "column" : "row"} gap={useRow ? 2 : 0}>
                                <div>
                                    <Grid container border={`1px solid GrayText`} borderRadius={2} overflow="hidden" sx={{
                                        ...(disabledNext && {
                                            backgroundColor: '#edebeb'
                                        })
                                    }}>
                                        {
                                            Array(4).fill(null).map((_, index) => {
                                                const key = `predication${predication_index + 1}`
                                                const ITEM_ID = (index - predication_index) > 0 ? ((index)) : index
                                                const predicationItem = predictions ? predictions[key] : null
                                                const valueItem = predicationItem ? predicationItem.find((_item) => _item.id === ITEM_ID) : null

                                                const refPosition = ((index) + (predication_index) * 4)
                                                return (
                                                    <Grid
                                                        key={index}
                                                        width={60}
                                                        height={60}
                                                        container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        borderRight={index !== 3 ? `1px solid GrayText` : "none"}>
                                                        <PredictionInput
                                                            index={index}
                                                            isFocus={activeInput === refPosition}
                                                            isFavorite={index === 3}
                                                            disable={loadingRandomNumbers || disabledNext}
                                                            inputRef={(el) => inputsRef.current[refPosition] = el}
                                                            value={valueItem?.number ?? undefined}
                                                            onDelete={() => {
                                                                handlePredictionChange(key, (predicationItem ?? []).filter((item) => item.id !== index))
                                                                if (refPosition > 0) {
                                                                    inputsRef.current[refPosition - 1]?.focus()
                                                                }
                                                            }}
                                                            onKeyDown={(keyCode) => {
                                                                let value = Number(keyCode)
                                                                if (!isNaN(value)) {
                                                                    if (index === 3 && predictions) {
                                                                        let newPredictions: PredictionPros[] = [
                                                                            ...predicationItem ?? [],
                                                                            {
                                                                                id: index,
                                                                                number: value
                                                                            }
                                                                        ]
                                                                        let exist = false

                                                                        for (let _pred of Object.keys(predictions ?? {})) {
                                                                            let pred = predictions[_pred]
                                                                            let isEqual = 0
                                                                            if (pred) {
                                                                                for (let obj of pred) {
                                                                                    let find = newPredictions.find((item) => item.id === obj.id && item.number === obj.number)
                                                                                    if (find) isEqual += 1
                                                                                }
                                                                            }
                                                                            if (isEqual === 4 && _pred !== key) {
                                                                                exist = true
                                                                            }
                                                                        }

                                                                        if (exist) {
                                                                            toast("A aposta não pode se repetir", {
                                                                                type: "warning"
                                                                            })
                                                                            return
                                                                        }
                                                                    }
                                                                    if (valueItem) {
                                                                        if (predicationItem) {
                                                                            let newPredictions: PredictionPros[] = [
                                                                                ...predicationItem.filter((item) => item.id !== index),
                                                                                {
                                                                                    id: index,
                                                                                    number: value
                                                                                }
                                                                            ]
                                                                            handlePredictionChange(key, newPredictions)
                                                                        }
                                                                    } else {
                                                                        if (predicationItem) {
                                                                            let newPredictions: PredictionPros[] = [
                                                                                ...predicationItem,
                                                                                {
                                                                                    id: index,
                                                                                    number: value
                                                                                }
                                                                            ]
                                                                            handlePredictionChange(key, newPredictions)
                                                                        } else {
                                                                            let newPredictions: PredictionPros[] = [
                                                                                {
                                                                                    id: index,
                                                                                    number: value
                                                                                }
                                                                            ]
                                                                            handlePredictionChange(key, newPredictions)
                                                                        }
                                                                    }
                                                                    if (refPosition !== ((order?.group as number * 4) - 1)) {
                                                                        inputsRef.current[refPosition + 1]?.focus()
                                                                    } else {
                                                                        inputsRef.current[refPosition]?.blur()
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(value) => {
                                                            }}
                                                            onFocusIn={() => setActiveInput(refPosition)}
                                                            onFocusOut={() => setActiveInput(null)}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </div>
                                <Grid display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                                    <Typography
                                        variant="h3"
                                        fontWeight="bold">
                                        Aposta {predication_index + 1}
                                    </Typography>
                                    {(predictions && predictions[`predication${predication_index + 1}`]?.length === 4) && <FaCheck color={palette.success.main} />}
                                </Grid>
                            </Grid>
                        )
                    })
                }
                {canContinue && luckyNumbers === null &&
                    <Grid item xs={12} display={"flex"} alignItems={"flex-end"} justifyContent={"flex-end"}>
                        <Button variant="outlined" onClick={() => {
                            setFavoriteNumber(null)
                            setPrediction(null)
                            setLuckNumbers(null)
                            sendToShow("favorite_number", 'start')
                            setAlreadyGeneratedNumbers(false)
                            inputsRef.current[0]?.focus()
                        }}
                            sx={{
                                fontSize: 12,
                                color: "GrayText",
                                borderColor: "GrayText",
                            }}
                        >Apagar apostas</Button>
                    </Grid>
                }

                {
                    canContinue && luckyNumbers === null &&
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        id="generate_luck_numbers"
                        sx={{ mt: 2, padding: '20px 0px', animation: 'pulseWithoutBoxShadow 2s infinite' }}
                        fullWidth
                        disabled={!canContinue || loadingGenerate}
                        onClick={() => {
                            navigate(RouterPath.bet.order2.path)
                        }}
                    >
                        <Grid>
                            {!loadingGenerate && <Typography
                                color={palette.common.white}
                                variant="h3"
                                textTransform={"none"}>
                                Clique para
                            </Typography>}
                            <Typography
                                color={palette.common.white}
                                textTransform="uppercase"
                                sx={{ textDecoration: 'underline' }}
                                variant="h3"
                                fontWeight="bold">
                                {loadingGenerate ? <CircularProgress /> : "Continuar"}
                            </Typography>
                        </Grid>
                    </Button>
                }
            </Grid>

            {
                luckyNumbers &&
                <NumbersOptions />
            }
            {/* <ShowNumbersLuck
                open={!!showModalNumbersLuck}
                value={showModalNumbersLuck}
                handleClose={() => setShowModalNumbersLuck(null)}
            /> */}
            <Grid height={100} />
        </Grid >
    )
}

export default FavoriteNumber;