import { LoadingButton } from "@mui/lab";
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useTheme } from "@mui/material"
import useBet from "context/bet";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { SellerService } from "service/seller";
import Logo from "assets/imgs/logo.svg"
import { MdOutlineOndemandVideo } from "react-icons/md";
import ThumbMobile from 'assets/imgs/lp/whoWeAre/COMO APOSTAR 02.png'
import { sendToShow } from "utils";

export const RequestSeller = ({
    open,
    handleClose
}: {
    open: boolean,
    handleClose: (success: boolean) => void;

}) => {

    const { palette } = useTheme()

    const { setSaller } = useBet()

    const [data, setData] = useState<{
        [key: number]: string | null
    }>({
        0: null,
        1: null,
        2: null,
        3: null
    })

    const [disabled, setDisabled] = useState(false)

    const [activeInput, setActiveInput] = useState<null | number>(null)

    const [loading, setLoading] = useState(false);

    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    const validNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

    const [openCollapse, setOpenCollapse] = useState(false)

    const searchSeller = async () => {
        setLoading(true)

        await SellerService.getAlternative(Object.values(data).join(""))
            .then(({ data }) => {
                setSaller(data)
                handleClose(true)
            }).catch((err) => {
                toast(err?.response?.data?.message?.replace("Vendedor", "Revendedor") ?? "Erro ao buscar revendedor", {
                    type: 'error'
                })
            })
        setLoading(false)
    }

    useEffect(() => {
        setTimeout(() => {
            if (!inputsRef?.current[0]?.value) {
                inputsRef?.current[0]?.focus()
            }
        }, 100)
    }, [])


    return (
        <Dialog
            fullScreen={window.screen.width < 700}
            open={open}
            maxWidth={"xs"}
        >
            <DialogTitle>
                <Grid container justifyContent={"center"} mb={4}>
                    <img src={Logo} alt={"Logo"} />
                </Grid>
            </DialogTitle>
            <DialogContent sx={{
                mt: 2
            }}>
                <Typography textAlign={"center"} variant="h3" fontWeight={400}>Para APOSTAR AGORA, <strong>DIGITE ABAIXO o CÓDIGO DO REVENDEDOR</strong> que está no verso do seu panfleto.</Typography>

                <Grid id={"numbers"} container flexDirection={"row"} flexWrap={"nowrap"} gap={.5} mt={2} alignItems="center" justifyContent={'center'}>
                    {
                        Object.keys(data).map((_, index) => (
                            <Grid
                                container
                                borderRadius={2}
                                border={`1px solid ${activeInput === index ? palette.primary.main : palette.grey[500]}`}
                                p={2}
                                alignItems="center"
                                justifyContent={'center'}
                                maxWidth={65}
                                width={'100%'}
                                bgcolor="white"
                                style={{
                                    transition: 'all .3s ease',
                                    ...(activeInput === index && {
                                        transform: 'scale(1.1)'
                                    }),
                                    ...(activeInput !== index && activeInput !== null && {
                                        transform: 'scale(.9)'
                                    })
                                }}
                            >
                                <input
                                    disabled={disabled || loading}
                                    style={{
                                        fontSize: '16px',
                                        color: disabled || loading ? 'GrayText' : palette.primary.main
                                    }}
                                    ref={(el) => inputsRef.current[index] = el}
                                    inputMode="numeric"
                                    value={data[index as 0 | 1 | 2] ?? ''}
                                    onChange={(ev) => {
                                        const value = ev.target.value.slice(-1)
                                        if (validNumbers.includes(value.toString())) {
                                            setData(prev => ({
                                                ...prev,
                                                [index]: value.toString()
                                            }))
                                            inputsRef.current[index + 1]?.focus();
                                        }
                                    }}
                                    onKeyDown={(ev) => {
                                        if (ev.keyCode === 8) {
                                            setData(prev => ({
                                                ...prev,
                                                [index]: null
                                            }))
                                            inputsRef.current[index - 1]?.focus();
                                        }
                                    }}
                                    onFocus={() => setActiveInput(index)}
                                    onBlur={() => setActiveInput(null)}
                                    className="input_number_fav"
                                />
                            </Grid>
                        ))
                    }
                </Grid>

                <Grid container justifyContent={"center"} direction={"column"} gap={4} mt={2}>

                    <Grid container justifyContent={"center"} direction={"column"} gap={4} mt={4}>
                        <LoadingButton variant="contained" color="secondary" sx={{
                            height: '50px',
                            mb: .5,
                            ... (!(loading || Object.values(data).includes(null)) && {
                                animation: 'pulseWithoutBoxShadow 1.5s infinite'
                            })
                        }} disabled={loading || Object.values(data).includes(null)} onClick={searchSeller} loading={loading}>Clique para continuar</LoadingButton>
                    </Grid>

                    <Button variant="outlined" sx={{
                        color: 'GrayText',
                        borderColor: 'GrayText',
                        textTransform: 'none',
                        display: "flex",
                        flexDirection: "column"
                    }} onClick={() => {
                        setOpenCollapse(true)
                        setTimeout(() => {
                            sendToShow("video-tutorial", "end")
                        }, 500)
                    }} color="inherit"
                    >
                        COMO APOSTAR?
                        <Grid container justifyContent={"center"}>
                            <MdOutlineOndemandVideo size={24} />
                            Clique aqui para assistir o vídeo explicativo (2 minutos)
                        </Grid>

                    </Button>

                    <Collapse in={openCollapse} id={"video-tutorial"}>
                        <video controls controlsList="nodownload" preload="auto" playsInline poster={ThumbMobile} style={{
                            maxWidth: '100%'
                        }}>
                            <source src={"https://s3.amazonaws.com/assets.numerofavorito.com/videos+lp/COMO+APOSTAR+2.mp4"} type="video/mp4" />
                        </video>
                        <Button fullWidth onClick={() => {
                            setOpenCollapse(false)
                            sendToShow("number", "center")
                            inputsRef?.current[0]?.focus()
                        }}>Encerrar o vídeo</Button>
                    </Collapse>

                    <Button variant="outlined" sx={{
                        color: 'GrayText',
                        borderColor: 'GrayText',
                        textTransform: 'none'
                    }} onClick={() => {
                        handleClose(false)
                        setData({
                            0: null,
                            1: null,
                            2: null,
                            3: null
                        })
                    }} color="inherit">Não tenho o código do revendedor</Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}