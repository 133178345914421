import { Button, CircularProgress, Collapse, Divider, FormControl, Grid, Menu, MenuItem, Select, Typography, useTheme } from "@mui/material"
import moment from "moment";
import { useEffect, useState } from "react";
import { PrizeDrawsService } from "service/prizes";
import { ResultsPrizesProps, prizeDrawsProps } from "types";
import TableResults from "../myPrizeDraws/results";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Player } from "video-react";
import ThumbMobile from 'assets/imgs/lp/whoWeAre/background 3x4.png'
import useAuth from "context/auth";
import { Roles, sendToShow } from "utils";

const TutorialPage = () => {
    const { user } = useAuth()
    const [videos, setVideos] = useState<{
        id: number,
        url: string,
        label: string,
        open: boolean
    }[]>([])


    useEffect(() => {
        if (user) {
            let array = [
                {
                    id: 1,
                    label: "Como vender apostas presencialmente",
                    open: false,
                    url: "https://s3.amazonaws.com/assets.numerofavorito.com/videos+lp/COMO+VENDER+PRESENCIAL.mp4",
                },
            ]
            const onlySeller: any[] = [
                // {
                //     id: 2,
                //     label: "Aprenda a gerar link para venda",
                //     open: false,
                //     url: "https://s3.amazonaws.com/static.numerofavorito.com/tutorial/aprenda+a+gerar+link+de+venda.mp4"
                // },
                // {
                //     id: 3,
                //     label: "Aprenda a vender presencialmente",
                //     open: false,
                //     url: "https://s3.amazonaws.com/static.numerofavorito.com/tutorial/aprenda+a+vender+presencialmente.mp4"
                // }
            ]

            if (user?.role === Roles.seller) {
                array = array.concat(onlySeller)
            }

            setVideos(array)
        }
    }, [user])


    const playVideo= (id: number) => {
        const key = `video-${id}`
        setTimeout(() => {
            const video = document.getElementById(key) as HTMLVideoElement
            if (video) {
                video.play().catch((err) => console.log(err))
            }
            sendToShow(key, 'start')
        }, 500);
    } 


    return (
        <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'} alignItems="center">
            <Typography variant="h2" textAlign={"center"} fontWeight={"bold"}>
                Aprenda aqui
            </Typography>
            {
                videos.map((_v) => {
                    return (
                        <>
                            <Button
                                fullWidth
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    textTransform: 'uppercase'
                                }}
                                endIcon={_v.open ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                onClick={() => {
                                    if (_v.open) {
                                        let vid = document.getElementById(`video-${_v.id}`) as HTMLVideoElement;
                                        vid?.pause()
                                    }
                                    setVideos(videos.map((video) => {
                                        if (video.id === _v.id) {
                                            return {
                                                ...video,
                                                open: !_v.open
                                            }
                                        } else {
                                            return video
                                        }
                                    }))

                                    playVideo(_v.id)
                                }}
                            >
                                {_v.label}
                            </Button>
                            <Collapse in={_v.open}>
                                <Grid container maxWidth={400}>
                                    <video id={`video-${_v.id}`} style={{
                                        width: '100%',
                                        height: "100%"
                                    }} controls controlsList="nodownload" preload="auto" playsInline poster={ThumbMobile}>
                                        <source src={_v.url} type="video/mp4" />
                                    </video>
                                </Grid>
                            </Collapse>
                        </>
                    )
                })
            }
        </Grid>
    )
}
export default TutorialPage;