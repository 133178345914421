import { keyPixTypeProp } from "components/withdraw";
import { Utils } from "utils";

export default class StorageService {
    static getUser() {
        const user = localStorage.getItem(Utils.storageLocales.NFVtoken)
        if (user) {
            return JSON.parse(user)
        }
        return null
    }

    static async saveToken(data: any) {
        if (!data) {
            return
        }
        localStorage.setItem(Utils.storageLocales.NFVtoken, JSON.stringify(data));
    }

    static async getToken() {
        const user = localStorage.getItem(Utils.storageLocales.NFVtoken)
        if (user) {
            const user_json = JSON.parse(user)
            return user_json?.access
        }
        return null
    }

    static async saveTokenClient(data: any) {
        if (!data) {
            return
        }
        localStorage.setItem(Utils.storageLocales.NFVtokenClient, JSON.stringify(data));
    }

    static getTokeClient() {
        const user = localStorage.getItem(Utils.storageLocales.NFVtokenClient)
        if (user) {
            const user_json = JSON.parse(user)
            return user_json
        }
        return null
    }


    static ViewComissionSeller(chageValue?: string) {
        if (chageValue) {
            localStorage.setItem(Utils.storageLocales.NFVSellerComissionShow, chageValue)
            return
        }
        const comissionshow = localStorage.getItem(Utils.storageLocales.NFVSellerComissionShow)
        if (comissionshow) {
            return comissionshow
        }
        return null
    }

    static getPixKey(): { key: string, kind: keyPixTypeProp } | null {
        const pixkey = localStorage.getItem(Utils.storageLocales.NFVKeyPix)
        if (pixkey) {
            const __pixkey: { key: string, kind: keyPixTypeProp } = JSON.parse(pixkey)
            return __pixkey
        }
        return null
    }

    static async savePixKey(data: { key: string, kind: string }) {
        if (data) {
            localStorage.setItem(Utils.storageLocales.NFVKeyPix, JSON.stringify(data))
        }
    }

    static async deletePixKey() {
        localStorage.removeItem(Utils.storageLocales.NFVKeyPix)
    }

    static async deleteToken(key: string) {
        localStorage.removeItem(key)
    }

    static getCountClickModalFirstPurchase() {
        return localStorage.getItem(Utils.storageLocales.NFVCountClickModalFirstPurchase)
    }

    static async saveCountClickModalFirstPurchase(count: string) {
        localStorage.setItem(Utils.storageLocales.NFVCountClickModalFirstPurchase, count)
    }

    static getCountClickModal30(): string[] {
        return JSON.parse(localStorage.getItem(Utils.storageLocales.NFVCountClickModal30) as string)
    }

    static async saveCountClickModal30(newArray: string[]) {
        localStorage.setItem(Utils.storageLocales.NFVCountClickModal30, JSON.stringify(newArray))
    }
}