import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import PaymentCancel from "components/paymentCancel"
import PaymentMade from "components/paymentMade"
import useAuth from "context/auth"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { BetService } from "service/bets"
import { Bet } from "types/bet"
import { awaitTime } from "utils"
import { phoneMask } from "utils/mask"
import RoutesPath from "utils/routesBases"

interface IProps {
    open: boolean,
    bet: Bet,
}

export const AwaitWhatsAppConfirmation = ({
    bet,
    open,

}: IProps) => {
    const navigate = useNavigate();
    const { user } = useAuth()
    const [paymentMade, setPaymentMade] = useState(false);
    const [paymentCancel, setPaymentCancel] = useState(false);
    const [recibo, setRecibo] = useState(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            getBet()
        }
    }, [open])

    useEffect(() => {
        if (paymentMade) {
            setTimeout(() => {
                navigate(RoutesPath.Revendedor.bet.recibo2.path + '/' + bet?.uuid, {
                    state: {
                        ...(recibo ?? {})
                    }
                })
            }, 3000)
        }
    }, [paymentMade])

    useEffect(() => {
        if (paymentCancel) {
            setTimeout(() => {
                navigate(RoutesPath.Revendedor.path)
            }, 3000)
        }
    }, [paymentCancel])

    const getBet = async () => {
        if (!bet) return
        await BetService.inspect(bet?.uuid as string).then(async ({ data }) => {
            if (!data) {
                setPaymentCancel(true)
                return
            }
            if (data?.status === 4) {
                setRecibo(data)
                setPaymentMade(true)
            } else if (data?.status === 5) {
                setPaymentCancel(true)
            } else {
                await awaitTime(2);
                return getBet();
            }
        })
    }

    const cancelBet = async () => {
        setLoading(true)
        if (bet?.id) {
            await BetService.cancel(bet?.id)
        }
        setLoading(false)
        setPaymentCancel(true)
    }

    return (
        <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Typography variant="h4" textAlign="center">
                    Enviamos uma mensagem para seu WhatsApp {phoneMask(user?.personalData?.phone as string)}.
                    <br />
                    <br />
                    Verifique seu WhatsApp, para autorizar o pagamento dessa aposta utilizando seu saldo disponível, responda: Sim, eu confirmo.
                </Typography>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    sx={{
                        color: 'GrayText'
                    }}
                    variant="text"
                    loading={loading}
                    disabled={loading}
                    onClick={cancelBet}
                >Cancelar aposta</LoadingButton>
            </DialogActions>
            <PaymentCancel
                show={paymentCancel}
            />
            <PaymentMade
                show={paymentMade}
            />
        </Dialog>
    )
}