import React, {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";
import { useLocation } from "react-router-dom";
import { AuthService } from "service/auth";
import StorageService from "service/storage";
import UserProps, { TokenProps } from "types/user";

interface ContextProps {
    Authenticate: (a: any, type: 'client' | 'seller' | 'general') => void;
    isAuthenticated: boolean;
    user: UserProps | null
    token: TokenProps | null;
    tokenClient: TokenProps | null;
}

export const AuthManager = createContext({} as ContextProps);

const useAuth = () => useContext(AuthManager);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {

    const { pathname } = useLocation();

    const [token, seToken] = useState<TokenProps | null>(() => {
        const current_user = StorageService.getUser()
        return current_user ?? null

    });

    const [tokenClient, seTokenClient] = useState<TokenProps | null>(() => {
        const current_user = StorageService.getTokeClient()
        return current_user ?? null

    });

    const [user, setUser] = useState<UserProps | null>(null);


    const Authenticate = (data: any, type: 'client' | 'seller' | 'general') => {
        if (type === 'client') {
            seTokenClient(data)
            StorageService.saveTokenClient(data)
            return
        }
        seToken(data)
        StorageService.saveToken(data)
    }

    useEffect(() => {
        if (token && (pathname.includes('correspondente') || pathname.includes('venda') || pathname.includes('geral'))) {
            AuthService.me()
                .then(({ data }) => {
                    setUser(data)
                    if (data?.pix) {
                        StorageService.savePixKey(data.pix)
                    } 
                    if (data?.status === 2 || data === null || data?.blocked) {
                        AuthService.logout()
                    }
                    return
                })
        }
        if (tokenClient && (pathname.includes('cliente') || pathname.includes("apostar"))) {
            AuthService.clientMe()
                .then(({ data }) => {
                    setUser(data)
                    if (data?.pix) {
                        StorageService.savePixKey(data.pix)
                    }
                    if (data?.status === 2 || data === null || data?.blocked) {
                        AuthService.logout()
                    }
                    return
                })
                .catch(() => { })
        }
    }, [token, tokenClient, pathname])

    return (
        <AuthManager.Provider value={{
            isAuthenticated: !!token || !!tokenClient,
            Authenticate,
            user,
            token,
            tokenClient
        }}>
            {children}
        </AuthManager.Provider>
    )
}

export default useAuth;