import { Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, Typography, useTheme } from "@mui/material"
import useBet from "context/bet";
import { FiPrinter } from "react-icons/fi";

import printJS from "print-js";
import { IoCheckmarkCircle, IoLogoWhatsapp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RoutesPath from "utils/routesBases";
import { BetService } from "service/bets";
import { toast } from "react-toastify";
import { awaitTime } from "utils";

const VoucherPage = () => {
    const navigate = useNavigate();

    const { ResetBet, purchaseFlow, sellerUuid, clientThatIndicate, payment } = useBet();

    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Revendedor

    const { id } = useParams();

    const [requestedVoucher, setRequestedVoucher] = useState(false)

    const [bet, setBet] = useState<any>();

    const [certificateGenerated, setCertificateGenerated] = useState(false);

    useEffect(() => {
        getBet()
    }, [])


    useEffect(() => {
        ResetBet();
    }, [])

    const handleVoucher = async (send: boolean) => {
        setRequestedVoucher(true)
        if (Boolean(send)) {
            BetService.sendVoucher(bet?.id)
        }
    }

    const getBet = async () => {
        await BetService.inspect(id as string).then(async ({ data }) => {
            if (!data) {
                toast("Certificado não encontrado", {
                    type: "error"
                })
                setTimeout(() => {
                    navigate(RoutesPath.Clients.path)
                }, 1000);
            }
            if (!data.certificateInfo?.html) {
                await awaitTime(2);
                return getBet();
            } else {
                setBet(data)
                setCertificateGenerated(true)
            }
        })
    }

    return (
        <>
            {certificateGenerated ? <Grid container gap={3}>
                <iframe
                    style={{
                        border: 'none',
                        width: '100%',
                        height: '72rem',
                        marginTop: 25
                    }}
                    src={bet?.certificateInfo?.html}>
                </iframe>
                <Grid container alignItems={"center"} justifyContent={"center"} gap={2} py={2} flexDirection={'column'}>
                    {/* <Button onClick={() => printJS({
                        printable: bet?.certificateInfo?.pdf,
                        type: 'pdf',
                        showModal: false
                    })} variant="outlined" startIcon={<FiPrinter />}>
                        Imprimir
                    </Button> */}
                    <Button
                        component="a"
                        target="_blank"
                        href={bet?.certificateInfo?.pdf}
                        variant="text"
                        fullWidth
                        sx={{ maxWidth: 200, p: 2, mt: 2 }}>
                        Baixar PDF
                    </Button>
                </Grid>
                <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Grid xs={6} container justifyContent="center" gap={2}>
                        <IoCheckmarkCircle color="#316FE8" size={30} />
                        <Typography textAlign="center" fontWeight={'bold'}>
                            Bilhete da Sorte emitido com sucesso
                        </Typography>
                    </Grid>
                </Grid>
                {/* {!requestedVoucher && <Grid container padding={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#25D366",
                            '&:hover': {
                                backgroundColor: "#199347"
                            },
                            textTransform: "inherit"
                        }}
                        onClick={() => {
                            handleVoucher(true)
                        }}
                    >Se preferir, clique para {purchaseFlow === "saller" ? "enviar" : "receber"} o Bilhete da Sorte {purchaseFlow === "saller" ? "para o WhatsApp do cliente" : "em seu WhatsApp"}</Button>
                </Grid>} */}
                <Grid p={2} container justifyContent="center" flexDirection={"column"} alignItems={"center"}>
                    <Button
                        onClick={() => navigate(RouterPath.path + (sellerUuid ? `?s=${sellerUuid}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}` : ''))}
                        variant="contained"
                        fullWidth
                        sx={{ 
                            maxWidth: 200, 
                            p: 2, 
                            animation: 'pulseWithoutBoxShadow 2s infinite'
                    }}>
                        Finalizar
                    </Button>
                </Grid>
            </Grid>
                :

                <Grid container mt={10} justifyContent={"center"} alignItems={"center"} direction={"column"} gap={3}>
                    <Typography>Estamos gerando seu bilhete da sorte...</Typography>
                    <CircularProgress />
                </Grid>
            }
        </>
    )
}

export default VoucherPage;