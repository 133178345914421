import BetPage from "pages/bet";
import OrderBetPage from "pages/bet/order";
import PaymentBetPage from "pages/bet/order/payment";
import AppLayout from "layout/saller";
import VoucherPage from "pages/bet/order/recibo";
import useQuery from "hooks/useQuery";
import LearnBetPage from "pages/bet/learn";
import BetLayout from "layout/bet";
import ClientLoginPage from "pages/auth/client/login";
import WithdrawPage from "components/withdraw";
import { ReactNode } from "react";
import ClientAppLayout from "layout/clients";
import ClientApp from "pages/client";
import ListVoucherPage from "pages/client/list";
import path from "path";
import { Videos } from "pages/videos/index";
import MyClients from "pages/correspondent/myClients";
import StatisticPage from "pages/correspondent/statistic";
import DepositPage from "components/deposit";

interface RouteElement {
    path: string;
    element: JSX.Element;
}

interface Routes {
    Clients: {
        path: string;
        pathRedirectacesso: string;
        bilhetes: {
            path: string;
            element: JSX.Element;
        };
        element: JSX.Element;
        pathRedirect: string;
        saque: RouteElement;
        login: RouteElement;
        bet: {
            home: RouteElement;
            order: RouteElement;
            payment: RouteElement;
            recibo: RouteElement;

            home2: RouteElement;
            order2: RouteElement;
            payment2: RouteElement;
            recibo2: RouteElement;
        };
        deposito: {
            path: string;
            element: JSX.Element;
        };
    };
    Correspondente: {
        path: string;
        saldo: string;
        saque: {
            path: string;
            element: JSX.Element;
        };
        deposito: {
            path: string;
            element: JSX.Element;
        };
        bet: {
            home: RouteElement;
            order: RouteElement;
            payment: RouteElement;
            recibo: RouteElement;

            home2: RouteElement;
            order2: RouteElement;
            payment2: RouteElement;
            recibo2: RouteElement;
        };
        meusClientes: RouteElement;
        statistic: RouteElement
    };
    Revendedor: {
        path: string;
        saldo: string;
        saque: {
            path: string;
            element: JSX.Element;
        };
        deposito: {
            path: string;
            element: JSX.Element;
        };
        bet: {
            home: RouteElement;
            order: RouteElement;
            payment: RouteElement;
            recibo: RouteElement;

            home2: RouteElement;
            order2: RouteElement;
            payment2: RouteElement;
            recibo2: RouteElement;
        };
        meusClientes: RouteElement;
        statistic: RouteElement
    };
    Geral: {
        meusClientes: RouteElement;
        statistic: RouteElement
    };
    Convitedasorte: {
        path: '/convitedasorte',
        order: '/convitedasorte/order',
        payment: '/convitedasorte/order/payment',
        recibo: '/convitedasorte/recibo',
    },
    aprenda: '/aprenda',
    sorteio: RouteElement,
}


const RoutesPath : Routes = {
    Clients: {
        path: "/cliente",
        element: <ClientApp />,
        bilhetes: {
            path: '/cliente/meus-bilhetes',
            element: <ListVoucherPage />
        },
        pathRedirect: '/cliente/app/meuacesso', 
        pathRedirectacesso: '/cliente/meuacesso',
        saque: {
            path: "/cliente/saque",
            element: <WithdrawPage />
        },
        deposito: {
            path: "/cliente/deposito",
            element: <DepositPage/>
        },
        login: {
            path: "/cliente/login",
            element: <ClientLoginPage />
        },
        bet: {
            home: {
                path: '/cliente/concurso',
                element: <BetPage />
            },
            order: {
                path: '/cliente/concurso/order',
                element: <OrderBetPage />
            },
            payment:{ 
                path: '/cliente/concurso/pagamento',
                element: <PaymentBetPage />
            },
            recibo: {
                path: '/cliente/concurso/recibo',
                element: <VoucherPage />
            },
            home2: {
                path: '/cliente/concurso',
                element: <BetPage />
            },
            order2: {
                path: '/cliente/concurso/order',
                element: <OrderBetPage />
            },
            payment2:{ 
                path: '/cliente/concurso/pagamento',
                element: <PaymentBetPage />
            },
            recibo2: {
                path: '/cliente/concurso/recibo',
                element: <VoucherPage />
            }
        },
    },
    Correspondente: {
        path: "/correspondente",
        saldo: "/correspondente/meusaldo",
        deposito: {
            path: "/correspondente/deposito",
            element: <DepositPage/>
        },
        saque: {
            path: "/correspondente/saque",
            element: <WithdrawPage />
        },
        bet: {
            home: {
                path: '/correspondente/venda',
                element: <BetPage />
            },
            
            order: {
                path: '/correspondente/venda/order',
                element: <OrderBetPage />
            },
            payment:{ 
                path: '/correspondente/venda/pagamento',
                element: <PaymentBetPage />
            },
            recibo: {
                path: '/correspondente/venda/recibo',
                element: <VoucherPage />
            },

            home2: {
                path: '/correspondente/aposta',
                element: <BetPage />
            },
            
            order2: {
                path: '/correspondente/aposta/order',
                element: <OrderBetPage />
            },
            payment2:{ 
                path: '/correspondente/aposta/pagamento',
                element: <PaymentBetPage />
            },
            recibo2: {
                path: '/correspondente/aposta/recibo',
                element: <VoucherPage />
            }
        },
        meusClientes: {
            path: "/correspondente/meus-clientes",
            element: <MyClients/>
        },
        statistic: {
            path: "/correspondente/estatistica",
            element: <StatisticPage/>
        }
    },
    Revendedor: {
        path: "/venda",
        saldo: "/venda/meusaldo",
        deposito: {
            path: "/venda/deposito",
            element: <DepositPage/>
        },
        saque: {
            path: "/venda/saque",
            element: <WithdrawPage />
        },
        bet: {
            home: {
                path: '/venda/venda',
                element: <BetPage />
            },
            
            order: {
                path: '/venda/venda/order',
                element: <OrderBetPage />
            },
            payment:{ 
                path: '/venda/venda/pagamento',
                element: <PaymentBetPage />
            },
            recibo: {
                path: '/venda/venda/recibo',
                element: <VoucherPage />
            },

            home2: {
                path: '/venda/aposta',
                element: <BetPage />
            },
            
            order2: {
                path: '/venda/aposta/order',
                element: <OrderBetPage />
            },
            payment2:{ 
                path: '/venda/aposta/pagamento',
                element: <PaymentBetPage />
            },
            recibo2: {
                path: '/venda/aposta/recibo',
                element: <VoucherPage />
            }
        },
        meusClientes: {
            path: "/venda/meus-clientes",
            element: <MyClients/>
        },
        statistic: {
            path: "/venda/estatistica",
            element: <StatisticPage/>
        }
    },
    Geral: {
        meusClientes: {
            path: "/geral/meus-clientes",
            element: <MyClients/>
        },
        statistic: {
            path: "/geral/estatistica",
            element: <StatisticPage/>
        }
    },
    Convitedasorte: {
        path: '/convitedasorte',
        order: '/convitedasorte/order',
        payment: '/convitedasorte/order/payment',
        recibo: '/convitedasorte/recibo',
    },
    aprenda: '/aprenda',
    sorteio: {
        path: '/sorteios',
        element: <Videos/>
    }
}

export default RoutesPath;