import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, Typography, useTheme } from '@mui/material';
import { PredictionInput } from 'components/prediction/input';
import { NumberInput } from 'components/input/numberInput';
import { AuthService } from 'service/auth';
import useAuth from 'context/auth';
import { toast } from 'react-toastify';
import { BetService } from 'service/bets';
import { useNavigate } from 'react-router-dom';
import useBet from 'context/bet';
import PaymentMade from 'components/paymentMade';
import Pin from 'components/pin';
import RoutesPath from 'utils/routesBases';


export interface PinProps {
    0: number | null,
    1: number | null,
    2: number | null
}

interface AlertAwaitAuthorizePaymenteProps {
    open: boolean,
    saller?: {
        betId: string | null
    } | null,
    client?: {
        clientId: number | null,
        betId: string | null
    } | null;
    purchaseFlow?: "client" | "saller";
}

export default function AlertAwaitAuthorizePaymente({ open, saller, client, purchaseFlow }: AlertAwaitAuthorizePaymenteProps) {
    const { payment, setSelectedDraw, } = useBet();
    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Revendedor
    const navigate = useNavigate();

    const { palette } = useTheme();

    const { user } = useAuth();
    const [paymentMade, setPaymentMade] = React.useState(false);

    const [recibo, setRecibo] = React.useState(null);

    const inputsRef = React.useRef<(HTMLInputElement | null)[]>([]);

    const [loading, setLoading] = React.useState(false);

    const [PinValues, setPinValues] = React.useState<PinProps>({
        0: null,
        1: null,
        2: null
    })

    const ResetPin = () => {
        setPinValues({
            0: null,
            1: null,
            2: null
        })
    }

    const OnCofirm = (token: string) => {
        setLoading(true)
        console.log(purchaseFlow)
        if (purchaseFlow === 'saller') {
            BetService.confirmSeller({ token, betId: payment?.bet?.uuid })
                .then(({ data }) => {
                    setRecibo(data)
                    if (payment?.bet?.paymentInfo?.valueToPay === 0) {
                        setPaymentMade(true)
                        return
                    }
                    navigate(RouterPath.bet.payment2.path + '/' + payment?.bet?.uuid, {
                        state: {
                            data
                        }
                    })
                })
                .catch((err) => {
                    toast(err?.response?.data?.message ?? "Error ao enviar token", { type: "error" })
                    ResetPin()
                })
                .finally(() => setLoading(false))
        }
        else {
            BetService.confirmClient({
                ...client,
                token
            })
                .then(({ data }) => {
                    setRecibo(data)
                    if (payment?.bet?.paymentInfo?.valueToPay === 0) {
                        setPaymentMade(true)
                        return
                    }
                    navigate(RouterPath.bet.payment2.path + '/' + payment?.bet?.uuid, {
                        state: {
                            data
                        }
                    })

                })
                .catch((err) => {
                    toast(err?.response?.data?.message ?? "Error ao enviar token", { type: "error" })
                    ResetPin()
                })
                .finally(() => setLoading(false))
        }
    }

    React.useEffect(() => {
        setPinValues({
            0: null,
            1: null,
            2: null
        })
    }, [saller, client])


    React.useEffect(() => {
        if (paymentMade) {
            setTimeout(() => {
                navigate(RouterPath.bet.recibo2.path + '/' + payment?.bet?.uuid, {
                    state: {
                        ...(recibo ?? {})
                    }
                })
            }, 3000)
        }
    }, [paymentMade])

    return (
        <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Typography variant="h4" textAlign="center">
                    Enviamos um novo código de segurança de 3 dígitos para seu <strong>SMS</strong> e <strong>Whatsapp</strong>.
                    <br />
                    Digite o código abaixo para <strong>continuar</strong>.
                </Typography>
                <Grid container flexDirection={"row"} flexWrap={"nowrap"} gap={1} mt={2}>
                    <Pin
                        desible={loading}
                        PinValues={PinValues}
                        onChangePin={(value) => {
                            setPinValues(value)
                        }}
                        onCofirm={OnCofirm}
                    />
                </Grid>
            </DialogContent>
            <PaymentMade
                show={paymentMade}
            />
        </Dialog>
    );
}