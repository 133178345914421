import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IndicationsProps from 'types/indications';
import RotateComponente from 'components/rotate';
import TableView from 'components/table';
import { ComissionReportProps } from 'types';
import moment from 'moment';
import { NameMask, phoneMask } from 'utils/mask';
import { IReport } from './interface';
import { BET_STATUS } from 'utils';
import { PaginationComponent } from 'components/pagination';

interface TableStatisticProps {
    open: boolean,
    handleClose: () => void;
    report: IReport[] | null,
    total: number,
    onChangePagination: (value: {
        page: number,
        limit: number
    }) => void

}

export default function TableEstatistic({ open, handleClose, report, onChangePagination, total }: TableStatisticProps) {

    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0 } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
            }}>
                <RotateComponente>
                    <TableView
                        align='center'
                        header={['ID', 'DATA E HORA', 'CLIENTE', 'VALOR', 'COMISSÃO', 'STATUS', 'N° APOSTAS', 'CONCURSO', 'REVENDEDOR', 'CIDADE DO REVENDEDOR', 'WHATSAPP DO REVENDEDOR']}
                    >
                        <TableBody>
                            {
                                report?.map(({
                                    betCreatedAt,
                                    betId,
                                    betNumbers,
                                    betStatus,
                                    betValue,
                                    certificateInfo,
                                    clientBirthday,
                                    clientId,
                                    clientName,
                                    clientPhone,
                                    confirmedAt,
                                    paymentInfo,
                                    prizeDrawAt,
                                    sellerCity,
                                    sellerId,
                                    sellerName,
                                    sellerPhone,
                                    sellerUf,
                                    prizeDrawId,
                                    commission
                                }, index) => {
                                    const color = index % 2 === 0 ? '#316FE840' : '#316FE824'
                                    const NameSeller = NameMask(sellerName ?? "", true) as unknown as string
                                    const NameClient = NameMask(clientName ?? "", true) as unknown as string

                                    return (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: color }}
                                        >
                                            <TableCell align="center">
                                                {betId}
                                            </TableCell>
                                            <TableCell align="center">
                                                {String(moment(betCreatedAt).format("dddd")).toUpperCase()} <br />
                                                {String(moment(betCreatedAt).format("L")).toUpperCase()} às {String(moment(betCreatedAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                                            </TableCell>
                                            <TableCell align="center">
                                                {NameClient}
                                            </TableCell>
                                            <TableCell align="center">
                                                {betValue.toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })}
                                            </TableCell>
                                            <TableCell align="center">
                                                {(commission ?? 0).toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })}
                                            </TableCell>
                                            <TableCell align="center">
                                                {BET_STATUS[betStatus]}
                                            </TableCell>
                                            <TableCell align="center">
                                                {betNumbers.length}
                                            </TableCell>
                                            <TableCell align="center">
                                                {prizeDrawId}, {String(moment(prizeDrawAt).format("dddd")).toUpperCase()} <br />
                                                {String(moment(prizeDrawAt).format("L")).toUpperCase()} às {String(moment(prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                                            </TableCell>
                                            <TableCell align="center">
                                                {NameSeller}
                                            </TableCell>
                                            <TableCell align="center">
                                                {sellerCity} - {sellerUf}
                                            </TableCell>
                                            <TableCell align="center">
                                                {phoneMask(sellerPhone)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </TableView>
                    <PaginationComponent
                        count={total}
                        handleClick={onChangePagination}  
                    />
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}