import CorrespondentApp from "pages/correspondent";
import AuthLayout from "layout/auth";
import AppLayout from "layout/saller";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import CorrespondentIndication from "pages/correspondent/indication";
import MyPrizeDrawsPage from "pages/correspondent/myPrizeDraws";
import TicketPage from "pages/correspondent/ticket";
import MyCommissions from "pages/correspondent/myCommissions";
import CommissionsTable from "pages/correspondent/comissionsTable";
import ResultPage from "pages/correspondent/results";
import CreditsSellerPage from "pages/correspondent/credits";
import WithdrawPage from "components/withdraw";
import RoutesPath from "utils/routesBases";
import useAuth from "context/auth";
import LoginPage from "pages/auth/correspondent/login";
import RegisterPage from "pages/auth/correspondent/register";
import TutorialPage from "pages/correspondent/tutorial";
import PubliPage from "pages/correspondent/publi";
import useQuery from "hooks/useQuery";
import { useEffect } from "react";

const SellerRouters = (path: string, pathRoute: string): RouteObject[] => {
    const { token } = useAuth();
    const { search } = useLocation()

    if (!token) {
        return [
            {
                element: <AuthLayout />,
                path: `${path}`,
                children: [
                    {
                        element: <Navigate to={`/${path}/login` + search}  />,
                        path: `/${path}`,
                    },
                    {
                        element: <LoginPage />,
                        path: `/${path}/login`,
                    },
                    {
                        element: <RegisterPage />,
                        path: `/${path}/cadastro`,
                    },
                ]
            },
        ]
    }

    return [
        {
            element: <AppLayout />,
            path: `${path}`,
            children: [
                {
                    element: <Navigate to={`/${path}`} />,
                    path: `/${path}/login`,
                },
                {
                    element: <Navigate to={`/${path}`} />,
                    path: `/${path}/*`,
                },
                {
                    element: <CorrespondentApp />,
                    path: `/${path}`,
                },
                {
                    element: <CorrespondentIndication />,
                    path: `/${path}/convite-revendedor`,
                },
                // {
                //     element: <MyPrizeDrawsPage />,
                //     path: `/${path}/meus-concursos`,
                // },
                {
                    element: <MyCommissions />,
                    path: `/${path}/minhas-comissoes`,
                },
                {
                    element: <CommissionsTable />,
                    path: `/${path}/tabela-comissoes`,
                },
                {
                    element: <CreditsSellerPage />,
                    path: `/${path}/meusaldo`,
                },
                {
                    element: <WithdrawPage />,
                    path: `/${path}/saque`,
                },
                {
                    element: <ResultPage />,
                    path: `/${path}/resultados`,
                },
                {
                    element: <TutorialPage />,
                    path: `/${path}/aprenda`,
                },
                {
                    element: <PubliPage />,
                    path: `/${path}/publicidade`,
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.home.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.home.path,
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.order.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.order.path,
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.payment.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.payment.path + '/:id',
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.recibo.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.recibo.path + '/:id',
                }, 
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.home2.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.home2.path,
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.order2.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.order2.path,
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.payment2.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.payment2.path + '/:id',
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.recibo2.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].bet.recibo2.path + '/:id',
                }, 
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].meusClientes.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].meusClientes.path,
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].statistic.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].statistic.path,
                },
                {
                    element: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].deposito.element,
                    path: RoutesPath[pathRoute as "Correspondente" | "Revendedor"].deposito.path
                }
            ]
        },
        {
            element: <TicketPage />,
            path: "/bilhete/:id",
        }
    ]
}

export default SellerRouters;