import { Button, CircularProgress, Collapse, Divider, FormControl, Grid, Menu, MenuItem, Select, Tooltip, Typography, useTheme } from "@mui/material"
import moment from "moment";
import { useEffect, useState } from "react";
import { PrizeDrawsService } from "service/prizes";
import { ResultsPrizesProps, prizeDrawsProps } from "types";
import TableResults from "../myPrizeDraws/results";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Player } from "video-react";
import ThumbMobile from 'assets/imgs/lp/whoWeAre/background 3x4.png'
import { Link, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver"
import theme from "theme";

const PubliPage = () => {
    const navigate = useNavigate()
    const [collapsePubli, setCollapsePubli] = useState(false)
    const [collapsePubli1, setCollapsePubli1] = useState(false)
    const [collapsePubli2, setCollapsePubli2] = useState(false)

    const images = {
        client: [
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/08+story.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/08+feed.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/07+story.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/07+feed.png",
            // "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/05+story.png",
            // "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/05+feed.png",
            // "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/04+story.png",
            // "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/04+feed.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/03+story.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/03+feed.png",
            "https://s3.amazonaws.com/assets.numerofavorito.com/publi/SORTEIO.jpeg"
        ],
        seller: [
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CORRESPONDENTE/01+feed.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CORRESPONDENTE/01+story.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CORRESPONDENTE/02+feed.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CORRESPONDENTE/02+story.png",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CORRESPONDENTE/03+feed.jpg",
            "https://s3.amazonaws.com/static.numerofavorito.com/publi/CORRESPONDENTE/03+story.jpg",
        ]
    }

    return (
        <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'} alignItems="center">
            <Typography variant="h2" textAlign={"center"} fontWeight={"bold"}>
                MATERIAL PUBLICITÁRIO
            </Typography>
            <Grid container gap={2} display={"flex"} flexDirection={"column"}>
                <Button variant="outlined" endIcon={collapsePubli ? <IoMdArrowDropup /> : <IoMdArrowDropdown />} onClick={() => setCollapsePubli(!collapsePubli)}>PUBLICIDADES PARA REDES SOCIAIS</Button>
                <Collapse in={collapsePubli}>
                    <Grid container gap={2} display={"flex"} flexDirection={"column"}>
                        <Grid display={"flex"} flexDirection={"row"} justifyContent={"space-between"} onClick={() => setCollapsePubli1(!collapsePubli1)}>
                            <Typography sx={{
                                cursor: 'pointer',
                                textIndent: 10
                            }} color={theme().palette.primary.main}>Publicidade para atrair novos clientes</Typography>
                            {collapsePubli1 ? <IoMdArrowDropup color={theme().palette.primary.main}/> : <IoMdArrowDropdown color={theme().palette.primary.main}/>}
                        </Grid>
                        <Collapse in={collapsePubli1}>
                            <Grid display={"flex"} flexDirection={"column"} gap={2}>
                                <Typography variant="caption" textAlign={"center"} color={"GrayText"}>Clique na imagem para baixar</Typography>
                                {images.client.map((img, i) => {
                                    return (
                                        <div>
                                            <Tooltip title="Clique para baixar" followCursor>
                                                <img src={img} width={"100%"} height={"auto"} onClick={async () => {
                                                    saveAs(img, img.split("https://s3.amazonaws.com/static.numerofavorito.com/publi/CLIENTE/")[1].replace("+", " ").replace(".png", ""))
                                                }} style={{
                                                    cursor: 'pointer'
                                                }} />
                                            </Tooltip >
                                            {i < images.client.length - 1 && <hr style={{
                                                margin: '10px 0px'
                                            }} />}
                                        </div>
                                    )
                                })}
                            </Grid>
                        </Collapse>
                        {/* <Grid display={"flex"} flexGrow={"row"} justifyContent={"space-between"} onClick={() => setCollapsePubli2(!collapsePubli2)}>
                            <Typography sx={{
                                cursor: 'pointer',
                                textIndent: 10
                            }} color={theme().palette.primary.main}>Publicidade para atrair novos revendedores</Typography>
                            {collapsePubli2 ? <IoMdArrowDropup color={theme().palette.primary.main}/> : <IoMdArrowDropdown color={theme().palette.primary.main}/>}
                        </Grid> */}
                        <Collapse in={collapsePubli2}>
                            <Grid display={"flex"} flexDirection={"column"} gap={2}>
                                <Typography variant="caption" textAlign={"center"} color={"GrayText"}>Clique na imagem para baixar</Typography>
                                {images.seller.map((img, i) => {
                                    return (
                                        <div>
                                            <Tooltip title="Clique para baixar" followCursor>
                                                <img src={img} width={"100%"} height={"auto"} onClick={async () => {
                                                    saveAs(img, img.split("https://s3.amazonaws.com/static.numerofavorito.com/publi/CORRESPONDENTE/")[1].replace("+", " ").replace(".png", ""))
                                                }} style={{
                                                    cursor: 'pointer'
                                                }} />
                                            </Tooltip>
                                            {i < images.seller.length - 1 && <hr style={{
                                                margin: '20px 0px',
                                            }} color="#6666666" />}
                                        </div>
                                    )
                                })}
                            </Grid>
                        </Collapse>
                    </Grid>
                </Collapse>
                <Link to="https://s3.amazonaws.com/static.numerofavorito.com/publi/MATERIAL+GR%C3%81FICO+N%C3%9AMERO+FAVORITO.rar">
                    <Button variant="outlined" fullWidth>PUBLICIDADE PARA CONFECÇÃO EM GRÁFICA</Button>
                </Link>
            </Grid>
            <Grid width="100%">
                <Button color="inherit" variant="outlined" sx={{ p: 1, fontSize: '1rem', maxWidth: 150 }} fullWidth onClick={() => navigate(-1)}>
                    Voltar
                </Button>
            </Grid>
        </Grid>
    )
}
export default PubliPage;